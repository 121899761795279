"use strict";
// Copyright 2020 Google LLC
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//    http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/*! THIS FILE IS AUTO-GENERATED */
Object.defineProperty(exports, "__esModule", { value: true });
exports.androidpublisher_v3 = exports.androidpublisher_v2 = exports.androidpublisher_v1 = exports.androidpublisher_v1_1 = exports.androidmanagement_v1 = exports.androidenterprise_v1 = exports.androiddeviceprovisioning_v1 = exports.analyticsreporting_v4 = exports.analyticshub_v1beta1 = exports.analyticshub_v1 = exports.analyticsdata_v1beta = exports.analyticsdata_v1alpha = exports.analyticsadmin_v1beta = exports.analyticsadmin_v1alpha = exports.analytics_v3 = exports.alloydb_v1beta = exports.alloydb_v1alpha = exports.alloydb_v1 = exports.alertcenter_v1beta1 = exports.airquality_v1 = exports.aiplatform_v1beta1 = exports.aiplatform_v1 = exports.advisorynotifications_v1 = exports.adsenseplatform_v1alpha = exports.adsenseplatform_v1 = exports.adsensehost_v4_1 = exports.adsense_v2 = exports.adsense_v1_4 = exports.admob_v1beta = exports.admob_v1 = exports.admin_reports_v1 = exports.admin_directory_v1 = exports.admin_datatransfer_v1 = exports.adexperiencereport_v1 = exports.adexchangebuyer2_v2beta1 = exports.adexchangebuyer_v1_4 = exports.adexchangebuyer_v1_3 = exports.adexchangebuyer_v1_2 = exports.addressvalidation_v1 = exports.acmedns_v1 = exports.accesscontextmanager_v1beta = exports.accesscontextmanager_v1 = exports.accessapproval_v1beta1 = exports.accessapproval_v1 = exports.acceleratedmobilepageurl_v1 = exports.abusiveexperiencereport_v1 = exports.Auth = exports.Common = exports.GoogleApis = exports.google = void 0;
exports.checks_v1alpha = exports.chat_v1 = exports.certificatemanager_v1 = exports.calendar_v3 = exports.businessprofileperformance_v1 = exports.books_v1 = exports.blogger_v3 = exports.blogger_v2 = exports.blockchainnodeengine_v1 = exports.binaryauthorization_v1beta1 = exports.binaryauthorization_v1 = exports.billingbudgets_v1beta1 = exports.billingbudgets_v1 = exports.bigtableadmin_v2 = exports.bigtableadmin_v1 = exports.bigqueryreservation_v1beta1 = exports.bigqueryreservation_v1alpha2 = exports.bigqueryreservation_v1 = exports.bigquerydatatransfer_v1 = exports.bigquerydatapolicy_v1 = exports.bigqueryconnection_v1beta1 = exports.bigqueryconnection_v1 = exports.bigquery_v2 = exports.biglake_v1 = exports.beyondcorp_v1alpha = exports.beyondcorp_v1 = exports.batch_v1 = exports.baremetalsolution_v2 = exports.baremetalsolution_v1alpha1 = exports.baremetalsolution_v1 = exports.backupdr_v1 = exports.authorizedbuyersmarketplace_v1alpha = exports.authorizedbuyersmarketplace_v1 = exports.assuredworkloads_v1beta1 = exports.assuredworkloads_v1 = exports.artifactregistry_v1beta2 = exports.artifactregistry_v1beta1 = exports.artifactregistry_v1 = exports.area120tables_v1alpha1 = exports.appsactivity_v1 = exports.apphub_v1alpha = exports.apphub_v1 = exports.appengine_v1beta = exports.appengine_v1alpha = exports.appengine_v1 = exports.apim_v1alpha = exports.apikeys_v2 = exports.apigeeregistry_v1 = exports.apigateway_v1beta = exports.apigateway_v1 = void 0;
exports.cloudtrace_v1 = exports.cloudtasks_v2beta3 = exports.cloudtasks_v2beta2 = exports.cloudtasks_v2 = exports.cloudsupport_v2beta = exports.cloudsupport_v2 = exports.cloudshell_v1alpha1 = exports.cloudshell_v1 = exports.cloudsearch_v1 = exports.cloudscheduler_v1beta1 = exports.cloudscheduler_v1 = exports.cloudresourcemanager_v3 = exports.cloudresourcemanager_v2beta1 = exports.cloudresourcemanager_v2 = exports.cloudresourcemanager_v1beta1 = exports.cloudresourcemanager_v1 = exports.cloudprofiler_v2 = exports.cloudkms_v1 = exports.cloudiot_v1 = exports.cloudidentity_v1beta1 = exports.cloudidentity_v1 = exports.cloudfunctions_v2beta = exports.cloudfunctions_v2alpha = exports.cloudfunctions_v2 = exports.cloudfunctions_v1beta2 = exports.cloudfunctions_v1 = exports.clouderrorreporting_v1beta1 = exports.clouddeploy_v1 = exports.clouddebugger_v2 = exports.cloudcontrolspartner_v1beta = exports.cloudcontrolspartner_v1 = exports.cloudchannel_v1 = exports.cloudbuild_v2 = exports.cloudbuild_v1beta1 = exports.cloudbuild_v1alpha2 = exports.cloudbuild_v1alpha1 = exports.cloudbuild_v1 = exports.cloudbilling_v1beta = exports.cloudbilling_v1 = exports.cloudasset_v1p7beta1 = exports.cloudasset_v1p5beta1 = exports.cloudasset_v1p4beta1 = exports.cloudasset_v1p1beta1 = exports.cloudasset_v1beta1 = exports.cloudasset_v1 = exports.classroom_v1 = exports.civicinfo_v2 = exports.chromeuxreport_v1 = exports.chromepolicy_v1 = exports.chromemanagement_v1 = void 0;
exports.dfareporting_v3_5 = exports.dfareporting_v3_4 = exports.dfareporting_v3_3 = exports.developerconnect_v1 = exports.deploymentmanager_v2beta = exports.deploymentmanager_v2 = exports.deploymentmanager_alpha = exports.datastream_v1alpha1 = exports.datastream_v1 = exports.datastore_v1beta3 = exports.datastore_v1beta1 = exports.datastore_v1 = exports.dataproc_v1beta2 = exports.dataproc_v1 = exports.dataportability_v1beta = exports.dataportability_v1 = exports.dataplex_v1 = exports.datapipelines_v1 = exports.datamigration_v1beta1 = exports.datamigration_v1 = exports.datalineage_v1 = exports.datalabeling_v1beta1 = exports.datafusion_v1beta1 = exports.datafusion_v1 = exports.dataform_v1beta1 = exports.dataflow_v1b3 = exports.datacatalog_v1beta1 = exports.datacatalog_v1 = exports.customsearch_v1 = exports.css_v1 = exports.contentwarehouse_v1 = exports.content_v2 = exports.content_v2_1 = exports.containeranalysis_v1beta1 = exports.containeranalysis_v1alpha1 = exports.containeranalysis_v1 = exports.container_v1beta1 = exports.container_v1 = exports.contactcenterinsights_v1 = exports.contactcenteraiplatform_v1alpha1 = exports.connectors_v2 = exports.connectors_v1 = exports.config_v1 = exports.compute_v1 = exports.compute_beta = exports.compute_alpha = exports.composer_v1beta1 = exports.composer_v1 = exports.cloudtrace_v2beta1 = exports.cloudtrace_v2 = void 0;
exports.firebaseappcheck_v1beta = exports.firebaseappcheck_v1 = exports.firebase_v1beta1 = exports.file_v1beta1 = exports.file_v1 = exports.fcmdata_v1beta1 = exports.fcm_v1 = exports.factchecktools_v1alpha1 = exports.eventarc_v1beta1 = exports.eventarc_v1 = exports.essentialcontacts_v1 = exports.drivelabels_v2beta = exports.drivelabels_v2 = exports.driveactivity_v2 = exports.drive_v3 = exports.drive_v2 = exports.doubleclicksearch_v2 = exports.doubleclickbidmanager_v2 = exports.doubleclickbidmanager_v1 = exports.doubleclickbidmanager_v1_1 = exports.domainsrdap_v1 = exports.domains_v1beta1 = exports.domains_v1alpha2 = exports.domains_v1 = exports.documentai_v1beta3 = exports.documentai_v1beta2 = exports.documentai_v1 = exports.docs_v1 = exports.dns_v2beta1 = exports.dns_v2 = exports.dns_v1beta2 = exports.dns_v1 = exports.dlp_v2 = exports.displayvideo_v4 = exports.displayvideo_v3 = exports.displayvideo_v2 = exports.displayvideo_v1dev = exports.displayvideo_v1beta2 = exports.displayvideo_v1beta = exports.displayvideo_v1 = exports.discoveryengine_v1beta = exports.discoveryengine_v1alpha = exports.discoveryengine_v1 = exports.discovery_v1 = exports.digitalassetlinks_v1 = exports.dialogflow_v3beta1 = exports.dialogflow_v3 = exports.dialogflow_v2beta1 = exports.dialogflow_v2 = exports.dfareporting_v4 = void 0;
exports.ideahub_v1beta = exports.ideahub_v1alpha = exports.iap_v1beta1 = exports.iap_v1 = exports.iamcredentials_v1 = exports.iam_v2beta = exports.iam_v2 = exports.iam_v1 = exports.homegraph_v1 = exports.healthcare_v1beta1 = exports.healthcare_v1 = exports.groupssettings_v1 = exports.groupsmigration_v1 = exports.gmailpostmastertools_v1beta1 = exports.gmailpostmastertools_v1 = exports.gmail_v1 = exports.gkeonprem_v1 = exports.gkehub_v2beta = exports.gkehub_v2alpha = exports.gkehub_v2 = exports.gkehub_v1beta1 = exports.gkehub_v1beta = exports.gkehub_v1alpha2 = exports.gkehub_v1alpha = exports.gkehub_v1 = exports.gkebackup_v1 = exports.genomics_v2alpha1 = exports.genomics_v1alpha2 = exports.genomics_v1 = exports.gameservices_v1beta = exports.gameservices_v1 = exports.gamesManagement_v1management = exports.gamesConfiguration_v1configuration = exports.games_v1 = exports.forms_v1 = exports.fitness_v1 = exports.firestore_v1beta2 = exports.firestore_v1beta1 = exports.firestore_v1 = exports.firebasestorage_v1beta = exports.firebaserules_v1 = exports.firebaseml_v2beta = exports.firebaseml_v1beta2 = exports.firebaseml_v1 = exports.firebasehosting_v1beta1 = exports.firebasehosting_v1 = exports.firebasedynamiclinks_v1 = exports.firebasedatabase_v1beta = exports.firebaseappdistribution_v1alpha = exports.firebaseappdistribution_v1 = void 0;
exports.mybusinessbusinesscalls_v1 = exports.mybusinessaccountmanagement_v1 = exports.monitoring_v3 = exports.monitoring_v1 = exports.ml_v1 = exports.migrationcenter_v1alpha1 = exports.migrationcenter_v1 = exports.metastore_v1beta = exports.metastore_v1alpha = exports.metastore_v1 = exports.merchantapi_reports_v1beta = exports.merchantapi_quota_v1beta = exports.merchantapi_promotions_v1beta = exports.merchantapi_products_v1beta = exports.merchantapi_notifications_v1beta = exports.merchantapi_lfp_v1beta = exports.merchantapi_inventories_v1beta = exports.merchantapi_datasources_v1beta = exports.merchantapi_conversions_v1beta = exports.merchantapi_accounts_v1beta = exports.memcache_v1beta2 = exports.memcache_v1 = exports.meet_v2 = exports.marketingplatformadmin_v1alpha = exports.manufacturers_v1 = exports.managedidentities_v1beta1 = exports.managedidentities_v1alpha1 = exports.managedidentities_v1 = exports.looker_v1 = exports.logging_v2 = exports.localservices_v1 = exports.lifesciences_v2beta = exports.licensing_v1 = exports.libraryagent_v1 = exports.language_v2 = exports.language_v1beta2 = exports.language_v1beta1 = exports.language_v1 = exports.kmsinventory_v1 = exports.kgsearch_v1 = exports.keep_v1 = exports.jobs_v4 = exports.jobs_v3p1beta1 = exports.jobs_v3 = exports.jobs_v2 = exports.integrations_v1alpha = exports.indexing_v3 = exports.ids_v1 = exports.identitytoolkit_v3 = exports.identitytoolkit_v2 = void 0;
exports.prod_tt_sasportal_v1alpha1 = exports.privateca_v1beta1 = exports.privateca_v1 = exports.poly_v1 = exports.pollen_v1 = exports.policytroubleshooter_v1beta = exports.policytroubleshooter_v1 = exports.policysimulator_v1beta1 = exports.policysimulator_v1beta = exports.policysimulator_v1alpha = exports.policysimulator_v1 = exports.policyanalyzer_v1beta1 = exports.policyanalyzer_v1 = exports.plus_v1 = exports.playintegrity_v1 = exports.playgrouping_v1alpha1 = exports.playdeveloperreporting_v1beta1 = exports.playdeveloperreporting_v1alpha1 = exports.playcustomapp_v1 = exports.playablelocations_v3 = exports.places_v1 = exports.people_v1 = exports.paymentsresellersubscription_v1 = exports.pagespeedonline_v5 = exports.oslogin_v1beta = exports.oslogin_v1alpha = exports.oslogin_v1 = exports.osconfig_v1beta = exports.osconfig_v1alpha = exports.osconfig_v1 = exports.orgpolicy_v2 = exports.ondemandscanning_v1beta1 = exports.ondemandscanning_v1 = exports.oauth2_v2 = exports.notebooks_v2 = exports.notebooks_v1 = exports.networkservices_v1beta1 = exports.networkservices_v1 = exports.networksecurity_v1beta1 = exports.networksecurity_v1 = exports.networkmanagement_v1beta1 = exports.networkmanagement_v1 = exports.networkconnectivity_v1alpha1 = exports.networkconnectivity_v1 = exports.mybusinessverifications_v1 = exports.mybusinessqanda_v1 = exports.mybusinessplaceactions_v1 = exports.mybusinessnotifications_v1 = exports.mybusinesslodging_v1 = exports.mybusinessbusinessinformation_v1 = void 0;
exports.servicedirectory_v1 = exports.servicecontrol_v2 = exports.servicecontrol_v1 = exports.serviceconsumermanagement_v1beta1 = exports.serviceconsumermanagement_v1 = exports.securitycenter_v1p1beta1 = exports.securitycenter_v1p1alpha1 = exports.securitycenter_v1beta2 = exports.securitycenter_v1beta1 = exports.securitycenter_v1 = exports.secretmanager_v1beta2 = exports.secretmanager_v1beta1 = exports.secretmanager_v1 = exports.searchconsole_v1 = exports.searchads360_v0 = exports.script_v1 = exports.sasportal_v1alpha1 = exports.safebrowsing_v5 = exports.safebrowsing_v4 = exports.runtimeconfig_v1beta1 = exports.runtimeconfig_v1 = exports.run_v2 = exports.run_v1beta1 = exports.run_v1alpha1 = exports.run_v1 = exports.retail_v2beta = exports.retail_v2alpha = exports.retail_v2 = exports.resourcesettings_v1 = exports.reseller_v1 = exports.remotebuildexecution_v2 = exports.remotebuildexecution_v1alpha = exports.remotebuildexecution_v1 = exports.redis_v1beta1 = exports.redis_v1 = exports.recommender_v1beta1 = exports.recommender_v1 = exports.recommendationengine_v1beta1 = exports.recaptchaenterprise_v1 = exports.realtimebidding_v1alpha = exports.realtimebidding_v1 = exports.readerrevenuesubscriptionlinking_v1 = exports.rapidmigrationassessment_v1 = exports.pubsublite_v1 = exports.pubsub_v1beta2 = exports.pubsub_v1beta1a = exports.pubsub_v1 = exports.publicca_v1beta1 = exports.publicca_v1alpha1 = exports.publicca_v1 = void 0;
exports.videointelligence_v1 = exports.versionhistory_v1 = exports.verifiedaccess_v2 = exports.verifiedaccess_v1 = exports.vectortile_v1 = exports.vault_v1 = exports.travelimpactmodel_v1 = exports.translate_v3beta1 = exports.translate_v3 = exports.translate_v2 = exports.transcoder_v1beta1 = exports.transcoder_v1 = exports.trafficdirector_v3 = exports.trafficdirector_v2 = exports.tpu_v2alpha1 = exports.tpu_v2 = exports.tpu_v1alpha1 = exports.tpu_v1 = exports.toolresults_v1beta3 = exports.texttospeech_v1beta1 = exports.texttospeech_v1 = exports.testing_v1 = exports.tasks_v1 = exports.tagmanager_v2 = exports.tagmanager_v1 = exports.sts_v1beta = exports.sts_v1 = exports.streetviewpublish_v1 = exports.storagetransfer_v1 = exports.storage_v1beta2 = exports.storage_v1 = exports.sqladmin_v1beta4 = exports.sqladmin_v1 = exports.sql_v1beta4 = exports.speech_v2beta1 = exports.speech_v1p1beta1 = exports.speech_v1 = exports.spanner_v1 = exports.sourcerepo_v1 = exports.solar_v1 = exports.smartdevicemanagement_v1 = exports.slides_v1 = exports.siteVerification_v1 = exports.sheets_v4 = exports.serviceusage_v1beta1 = exports.serviceusage_v1 = exports.servicenetworking_v1beta = exports.servicenetworking_v1 = exports.servicemanagement_v1 = exports.servicedirectory_v1beta1 = void 0;
exports.youtubereporting_v1 = exports.youtubeAnalytics_v2 = exports.youtubeAnalytics_v1 = exports.youtube_v3 = exports.workstations_v1beta = exports.workstations_v1 = exports.workspaceevents_v1 = exports.workloadmanager_v1 = exports.workflows_v1beta = exports.workflows_v1 = exports.workflowexecutions_v1beta = exports.workflowexecutions_v1 = exports.websecurityscanner_v1beta = exports.websecurityscanner_v1alpha = exports.websecurityscanner_v1 = exports.webrisk_v1 = exports.webmasters_v3 = exports.webfonts_v1 = exports.walletobjects_v1 = exports.vpcaccess_v1beta1 = exports.vpcaccess_v1 = exports.vmwareengine_v1 = exports.vmmigration_v1alpha1 = exports.vmmigration_v1 = exports.vision_v1p2beta1 = exports.vision_v1p1beta1 = exports.vision_v1 = exports.videointelligence_v1p3beta1 = exports.videointelligence_v1p2beta1 = exports.videointelligence_v1p1beta1 = exports.videointelligence_v1beta2 = void 0;
const googleapis_1 = require("./googleapis");
Object.defineProperty(exports, "GoogleApis", { enumerable: true, get: function () { return googleapis_1.GoogleApis; } });
const google = new googleapis_1.GoogleApis();
exports.google = google;
exports.Common = require("googleapis-common");
exports.Auth = require("google-auth-library");
var v1_1 = require("./apis/abusiveexperiencereport/v1");
Object.defineProperty(exports, "abusiveexperiencereport_v1", { enumerable: true, get: function () { return v1_1.abusiveexperiencereport_v1; } });
var v1_2 = require("./apis/acceleratedmobilepageurl/v1");
Object.defineProperty(exports, "acceleratedmobilepageurl_v1", { enumerable: true, get: function () { return v1_2.acceleratedmobilepageurl_v1; } });
var v1_3 = require("./apis/accessapproval/v1");
Object.defineProperty(exports, "accessapproval_v1", { enumerable: true, get: function () { return v1_3.accessapproval_v1; } });
var v1beta1_1 = require("./apis/accessapproval/v1beta1");
Object.defineProperty(exports, "accessapproval_v1beta1", { enumerable: true, get: function () { return v1beta1_1.accessapproval_v1beta1; } });
var v1_4 = require("./apis/accesscontextmanager/v1");
Object.defineProperty(exports, "accesscontextmanager_v1", { enumerable: true, get: function () { return v1_4.accesscontextmanager_v1; } });
var v1beta_1 = require("./apis/accesscontextmanager/v1beta");
Object.defineProperty(exports, "accesscontextmanager_v1beta", { enumerable: true, get: function () { return v1beta_1.accesscontextmanager_v1beta; } });
var v1_5 = require("./apis/acmedns/v1");
Object.defineProperty(exports, "acmedns_v1", { enumerable: true, get: function () { return v1_5.acmedns_v1; } });
var v1_6 = require("./apis/addressvalidation/v1");
Object.defineProperty(exports, "addressvalidation_v1", { enumerable: true, get: function () { return v1_6.addressvalidation_v1; } });
var v1_2_1 = require("./apis/adexchangebuyer/v1.2");
Object.defineProperty(exports, "adexchangebuyer_v1_2", { enumerable: true, get: function () { return v1_2_1.adexchangebuyer_v1_2; } });
var v1_3_1 = require("./apis/adexchangebuyer/v1.3");
Object.defineProperty(exports, "adexchangebuyer_v1_3", { enumerable: true, get: function () { return v1_3_1.adexchangebuyer_v1_3; } });
var v1_4_1 = require("./apis/adexchangebuyer/v1.4");
Object.defineProperty(exports, "adexchangebuyer_v1_4", { enumerable: true, get: function () { return v1_4_1.adexchangebuyer_v1_4; } });
var v2beta1_1 = require("./apis/adexchangebuyer2/v2beta1");
Object.defineProperty(exports, "adexchangebuyer2_v2beta1", { enumerable: true, get: function () { return v2beta1_1.adexchangebuyer2_v2beta1; } });
var v1_7 = require("./apis/adexperiencereport/v1");
Object.defineProperty(exports, "adexperiencereport_v1", { enumerable: true, get: function () { return v1_7.adexperiencereport_v1; } });
var datatransfer_v1_1 = require("./apis/admin/datatransfer_v1");
Object.defineProperty(exports, "admin_datatransfer_v1", { enumerable: true, get: function () { return datatransfer_v1_1.admin_datatransfer_v1; } });
var directory_v1_1 = require("./apis/admin/directory_v1");
Object.defineProperty(exports, "admin_directory_v1", { enumerable: true, get: function () { return directory_v1_1.admin_directory_v1; } });
var reports_v1_1 = require("./apis/admin/reports_v1");
Object.defineProperty(exports, "admin_reports_v1", { enumerable: true, get: function () { return reports_v1_1.admin_reports_v1; } });
var v1_8 = require("./apis/admob/v1");
Object.defineProperty(exports, "admob_v1", { enumerable: true, get: function () { return v1_8.admob_v1; } });
var v1beta_2 = require("./apis/admob/v1beta");
Object.defineProperty(exports, "admob_v1beta", { enumerable: true, get: function () { return v1beta_2.admob_v1beta; } });
var v1_4_2 = require("./apis/adsense/v1.4");
Object.defineProperty(exports, "adsense_v1_4", { enumerable: true, get: function () { return v1_4_2.adsense_v1_4; } });
var v2_1 = require("./apis/adsense/v2");
Object.defineProperty(exports, "adsense_v2", { enumerable: true, get: function () { return v2_1.adsense_v2; } });
var v4_1_1 = require("./apis/adsensehost/v4.1");
Object.defineProperty(exports, "adsensehost_v4_1", { enumerable: true, get: function () { return v4_1_1.adsensehost_v4_1; } });
var v1_9 = require("./apis/adsenseplatform/v1");
Object.defineProperty(exports, "adsenseplatform_v1", { enumerable: true, get: function () { return v1_9.adsenseplatform_v1; } });
var v1alpha_1 = require("./apis/adsenseplatform/v1alpha");
Object.defineProperty(exports, "adsenseplatform_v1alpha", { enumerable: true, get: function () { return v1alpha_1.adsenseplatform_v1alpha; } });
var v1_10 = require("./apis/advisorynotifications/v1");
Object.defineProperty(exports, "advisorynotifications_v1", { enumerable: true, get: function () { return v1_10.advisorynotifications_v1; } });
var v1_11 = require("./apis/aiplatform/v1");
Object.defineProperty(exports, "aiplatform_v1", { enumerable: true, get: function () { return v1_11.aiplatform_v1; } });
var v1beta1_2 = require("./apis/aiplatform/v1beta1");
Object.defineProperty(exports, "aiplatform_v1beta1", { enumerable: true, get: function () { return v1beta1_2.aiplatform_v1beta1; } });
var v1_12 = require("./apis/airquality/v1");
Object.defineProperty(exports, "airquality_v1", { enumerable: true, get: function () { return v1_12.airquality_v1; } });
var v1beta1_3 = require("./apis/alertcenter/v1beta1");
Object.defineProperty(exports, "alertcenter_v1beta1", { enumerable: true, get: function () { return v1beta1_3.alertcenter_v1beta1; } });
var v1_13 = require("./apis/alloydb/v1");
Object.defineProperty(exports, "alloydb_v1", { enumerable: true, get: function () { return v1_13.alloydb_v1; } });
var v1alpha_2 = require("./apis/alloydb/v1alpha");
Object.defineProperty(exports, "alloydb_v1alpha", { enumerable: true, get: function () { return v1alpha_2.alloydb_v1alpha; } });
var v1beta_3 = require("./apis/alloydb/v1beta");
Object.defineProperty(exports, "alloydb_v1beta", { enumerable: true, get: function () { return v1beta_3.alloydb_v1beta; } });
var v3_1 = require("./apis/analytics/v3");
Object.defineProperty(exports, "analytics_v3", { enumerable: true, get: function () { return v3_1.analytics_v3; } });
var v1alpha_3 = require("./apis/analyticsadmin/v1alpha");
Object.defineProperty(exports, "analyticsadmin_v1alpha", { enumerable: true, get: function () { return v1alpha_3.analyticsadmin_v1alpha; } });
var v1beta_4 = require("./apis/analyticsadmin/v1beta");
Object.defineProperty(exports, "analyticsadmin_v1beta", { enumerable: true, get: function () { return v1beta_4.analyticsadmin_v1beta; } });
var v1alpha_4 = require("./apis/analyticsdata/v1alpha");
Object.defineProperty(exports, "analyticsdata_v1alpha", { enumerable: true, get: function () { return v1alpha_4.analyticsdata_v1alpha; } });
var v1beta_5 = require("./apis/analyticsdata/v1beta");
Object.defineProperty(exports, "analyticsdata_v1beta", { enumerable: true, get: function () { return v1beta_5.analyticsdata_v1beta; } });
var v1_14 = require("./apis/analyticshub/v1");
Object.defineProperty(exports, "analyticshub_v1", { enumerable: true, get: function () { return v1_14.analyticshub_v1; } });
var v1beta1_4 = require("./apis/analyticshub/v1beta1");
Object.defineProperty(exports, "analyticshub_v1beta1", { enumerable: true, get: function () { return v1beta1_4.analyticshub_v1beta1; } });
var v4_1 = require("./apis/analyticsreporting/v4");
Object.defineProperty(exports, "analyticsreporting_v4", { enumerable: true, get: function () { return v4_1.analyticsreporting_v4; } });
var v1_15 = require("./apis/androiddeviceprovisioning/v1");
Object.defineProperty(exports, "androiddeviceprovisioning_v1", { enumerable: true, get: function () { return v1_15.androiddeviceprovisioning_v1; } });
var v1_16 = require("./apis/androidenterprise/v1");
Object.defineProperty(exports, "androidenterprise_v1", { enumerable: true, get: function () { return v1_16.androidenterprise_v1; } });
var v1_17 = require("./apis/androidmanagement/v1");
Object.defineProperty(exports, "androidmanagement_v1", { enumerable: true, get: function () { return v1_17.androidmanagement_v1; } });
var v1_1_1 = require("./apis/androidpublisher/v1.1");
Object.defineProperty(exports, "androidpublisher_v1_1", { enumerable: true, get: function () { return v1_1_1.androidpublisher_v1_1; } });
var v1_18 = require("./apis/androidpublisher/v1");
Object.defineProperty(exports, "androidpublisher_v1", { enumerable: true, get: function () { return v1_18.androidpublisher_v1; } });
var v2_2 = require("./apis/androidpublisher/v2");
Object.defineProperty(exports, "androidpublisher_v2", { enumerable: true, get: function () { return v2_2.androidpublisher_v2; } });
var v3_2 = require("./apis/androidpublisher/v3");
Object.defineProperty(exports, "androidpublisher_v3", { enumerable: true, get: function () { return v3_2.androidpublisher_v3; } });
var v1_19 = require("./apis/apigateway/v1");
Object.defineProperty(exports, "apigateway_v1", { enumerable: true, get: function () { return v1_19.apigateway_v1; } });
var v1beta_6 = require("./apis/apigateway/v1beta");
Object.defineProperty(exports, "apigateway_v1beta", { enumerable: true, get: function () { return v1beta_6.apigateway_v1beta; } });
var v1_20 = require("./apis/apigeeregistry/v1");
Object.defineProperty(exports, "apigeeregistry_v1", { enumerable: true, get: function () { return v1_20.apigeeregistry_v1; } });
var v2_3 = require("./apis/apikeys/v2");
Object.defineProperty(exports, "apikeys_v2", { enumerable: true, get: function () { return v2_3.apikeys_v2; } });
var v1alpha_5 = require("./apis/apim/v1alpha");
Object.defineProperty(exports, "apim_v1alpha", { enumerable: true, get: function () { return v1alpha_5.apim_v1alpha; } });
var v1_21 = require("./apis/appengine/v1");
Object.defineProperty(exports, "appengine_v1", { enumerable: true, get: function () { return v1_21.appengine_v1; } });
var v1alpha_6 = require("./apis/appengine/v1alpha");
Object.defineProperty(exports, "appengine_v1alpha", { enumerable: true, get: function () { return v1alpha_6.appengine_v1alpha; } });
var v1beta_7 = require("./apis/appengine/v1beta");
Object.defineProperty(exports, "appengine_v1beta", { enumerable: true, get: function () { return v1beta_7.appengine_v1beta; } });
var v1_22 = require("./apis/apphub/v1");
Object.defineProperty(exports, "apphub_v1", { enumerable: true, get: function () { return v1_22.apphub_v1; } });
var v1alpha_7 = require("./apis/apphub/v1alpha");
Object.defineProperty(exports, "apphub_v1alpha", { enumerable: true, get: function () { return v1alpha_7.apphub_v1alpha; } });
var v1_23 = require("./apis/appsactivity/v1");
Object.defineProperty(exports, "appsactivity_v1", { enumerable: true, get: function () { return v1_23.appsactivity_v1; } });
var v1alpha1_1 = require("./apis/area120tables/v1alpha1");
Object.defineProperty(exports, "area120tables_v1alpha1", { enumerable: true, get: function () { return v1alpha1_1.area120tables_v1alpha1; } });
var v1_24 = require("./apis/artifactregistry/v1");
Object.defineProperty(exports, "artifactregistry_v1", { enumerable: true, get: function () { return v1_24.artifactregistry_v1; } });
var v1beta1_5 = require("./apis/artifactregistry/v1beta1");
Object.defineProperty(exports, "artifactregistry_v1beta1", { enumerable: true, get: function () { return v1beta1_5.artifactregistry_v1beta1; } });
var v1beta2_1 = require("./apis/artifactregistry/v1beta2");
Object.defineProperty(exports, "artifactregistry_v1beta2", { enumerable: true, get: function () { return v1beta2_1.artifactregistry_v1beta2; } });
var v1_25 = require("./apis/assuredworkloads/v1");
Object.defineProperty(exports, "assuredworkloads_v1", { enumerable: true, get: function () { return v1_25.assuredworkloads_v1; } });
var v1beta1_6 = require("./apis/assuredworkloads/v1beta1");
Object.defineProperty(exports, "assuredworkloads_v1beta1", { enumerable: true, get: function () { return v1beta1_6.assuredworkloads_v1beta1; } });
var v1_26 = require("./apis/authorizedbuyersmarketplace/v1");
Object.defineProperty(exports, "authorizedbuyersmarketplace_v1", { enumerable: true, get: function () { return v1_26.authorizedbuyersmarketplace_v1; } });
var v1alpha_8 = require("./apis/authorizedbuyersmarketplace/v1alpha");
Object.defineProperty(exports, "authorizedbuyersmarketplace_v1alpha", { enumerable: true, get: function () { return v1alpha_8.authorizedbuyersmarketplace_v1alpha; } });
var v1_27 = require("./apis/backupdr/v1");
Object.defineProperty(exports, "backupdr_v1", { enumerable: true, get: function () { return v1_27.backupdr_v1; } });
var v1_28 = require("./apis/baremetalsolution/v1");
Object.defineProperty(exports, "baremetalsolution_v1", { enumerable: true, get: function () { return v1_28.baremetalsolution_v1; } });
var v1alpha1_2 = require("./apis/baremetalsolution/v1alpha1");
Object.defineProperty(exports, "baremetalsolution_v1alpha1", { enumerable: true, get: function () { return v1alpha1_2.baremetalsolution_v1alpha1; } });
var v2_4 = require("./apis/baremetalsolution/v2");
Object.defineProperty(exports, "baremetalsolution_v2", { enumerable: true, get: function () { return v2_4.baremetalsolution_v2; } });
var v1_29 = require("./apis/batch/v1");
Object.defineProperty(exports, "batch_v1", { enumerable: true, get: function () { return v1_29.batch_v1; } });
var v1_30 = require("./apis/beyondcorp/v1");
Object.defineProperty(exports, "beyondcorp_v1", { enumerable: true, get: function () { return v1_30.beyondcorp_v1; } });
var v1alpha_9 = require("./apis/beyondcorp/v1alpha");
Object.defineProperty(exports, "beyondcorp_v1alpha", { enumerable: true, get: function () { return v1alpha_9.beyondcorp_v1alpha; } });
var v1_31 = require("./apis/biglake/v1");
Object.defineProperty(exports, "biglake_v1", { enumerable: true, get: function () { return v1_31.biglake_v1; } });
var v2_5 = require("./apis/bigquery/v2");
Object.defineProperty(exports, "bigquery_v2", { enumerable: true, get: function () { return v2_5.bigquery_v2; } });
var v1_32 = require("./apis/bigqueryconnection/v1");
Object.defineProperty(exports, "bigqueryconnection_v1", { enumerable: true, get: function () { return v1_32.bigqueryconnection_v1; } });
var v1beta1_7 = require("./apis/bigqueryconnection/v1beta1");
Object.defineProperty(exports, "bigqueryconnection_v1beta1", { enumerable: true, get: function () { return v1beta1_7.bigqueryconnection_v1beta1; } });
var v1_33 = require("./apis/bigquerydatapolicy/v1");
Object.defineProperty(exports, "bigquerydatapolicy_v1", { enumerable: true, get: function () { return v1_33.bigquerydatapolicy_v1; } });
var v1_34 = require("./apis/bigquerydatatransfer/v1");
Object.defineProperty(exports, "bigquerydatatransfer_v1", { enumerable: true, get: function () { return v1_34.bigquerydatatransfer_v1; } });
var v1_35 = require("./apis/bigqueryreservation/v1");
Object.defineProperty(exports, "bigqueryreservation_v1", { enumerable: true, get: function () { return v1_35.bigqueryreservation_v1; } });
var v1alpha2_1 = require("./apis/bigqueryreservation/v1alpha2");
Object.defineProperty(exports, "bigqueryreservation_v1alpha2", { enumerable: true, get: function () { return v1alpha2_1.bigqueryreservation_v1alpha2; } });
var v1beta1_8 = require("./apis/bigqueryreservation/v1beta1");
Object.defineProperty(exports, "bigqueryreservation_v1beta1", { enumerable: true, get: function () { return v1beta1_8.bigqueryreservation_v1beta1; } });
var v1_36 = require("./apis/bigtableadmin/v1");
Object.defineProperty(exports, "bigtableadmin_v1", { enumerable: true, get: function () { return v1_36.bigtableadmin_v1; } });
var v2_6 = require("./apis/bigtableadmin/v2");
Object.defineProperty(exports, "bigtableadmin_v2", { enumerable: true, get: function () { return v2_6.bigtableadmin_v2; } });
var v1_37 = require("./apis/billingbudgets/v1");
Object.defineProperty(exports, "billingbudgets_v1", { enumerable: true, get: function () { return v1_37.billingbudgets_v1; } });
var v1beta1_9 = require("./apis/billingbudgets/v1beta1");
Object.defineProperty(exports, "billingbudgets_v1beta1", { enumerable: true, get: function () { return v1beta1_9.billingbudgets_v1beta1; } });
var v1_38 = require("./apis/binaryauthorization/v1");
Object.defineProperty(exports, "binaryauthorization_v1", { enumerable: true, get: function () { return v1_38.binaryauthorization_v1; } });
var v1beta1_10 = require("./apis/binaryauthorization/v1beta1");
Object.defineProperty(exports, "binaryauthorization_v1beta1", { enumerable: true, get: function () { return v1beta1_10.binaryauthorization_v1beta1; } });
var v1_39 = require("./apis/blockchainnodeengine/v1");
Object.defineProperty(exports, "blockchainnodeengine_v1", { enumerable: true, get: function () { return v1_39.blockchainnodeengine_v1; } });
var v2_7 = require("./apis/blogger/v2");
Object.defineProperty(exports, "blogger_v2", { enumerable: true, get: function () { return v2_7.blogger_v2; } });
var v3_3 = require("./apis/blogger/v3");
Object.defineProperty(exports, "blogger_v3", { enumerable: true, get: function () { return v3_3.blogger_v3; } });
var v1_40 = require("./apis/books/v1");
Object.defineProperty(exports, "books_v1", { enumerable: true, get: function () { return v1_40.books_v1; } });
var v1_41 = require("./apis/businessprofileperformance/v1");
Object.defineProperty(exports, "businessprofileperformance_v1", { enumerable: true, get: function () { return v1_41.businessprofileperformance_v1; } });
var v3_4 = require("./apis/calendar/v3");
Object.defineProperty(exports, "calendar_v3", { enumerable: true, get: function () { return v3_4.calendar_v3; } });
var v1_42 = require("./apis/certificatemanager/v1");
Object.defineProperty(exports, "certificatemanager_v1", { enumerable: true, get: function () { return v1_42.certificatemanager_v1; } });
var v1_43 = require("./apis/chat/v1");
Object.defineProperty(exports, "chat_v1", { enumerable: true, get: function () { return v1_43.chat_v1; } });
var v1alpha_10 = require("./apis/checks/v1alpha");
Object.defineProperty(exports, "checks_v1alpha", { enumerable: true, get: function () { return v1alpha_10.checks_v1alpha; } });
var v1_44 = require("./apis/chromemanagement/v1");
Object.defineProperty(exports, "chromemanagement_v1", { enumerable: true, get: function () { return v1_44.chromemanagement_v1; } });
var v1_45 = require("./apis/chromepolicy/v1");
Object.defineProperty(exports, "chromepolicy_v1", { enumerable: true, get: function () { return v1_45.chromepolicy_v1; } });
var v1_46 = require("./apis/chromeuxreport/v1");
Object.defineProperty(exports, "chromeuxreport_v1", { enumerable: true, get: function () { return v1_46.chromeuxreport_v1; } });
var v2_8 = require("./apis/civicinfo/v2");
Object.defineProperty(exports, "civicinfo_v2", { enumerable: true, get: function () { return v2_8.civicinfo_v2; } });
var v1_47 = require("./apis/classroom/v1");
Object.defineProperty(exports, "classroom_v1", { enumerable: true, get: function () { return v1_47.classroom_v1; } });
var v1_48 = require("./apis/cloudasset/v1");
Object.defineProperty(exports, "cloudasset_v1", { enumerable: true, get: function () { return v1_48.cloudasset_v1; } });
var v1beta1_11 = require("./apis/cloudasset/v1beta1");
Object.defineProperty(exports, "cloudasset_v1beta1", { enumerable: true, get: function () { return v1beta1_11.cloudasset_v1beta1; } });
var v1p1beta1_1 = require("./apis/cloudasset/v1p1beta1");
Object.defineProperty(exports, "cloudasset_v1p1beta1", { enumerable: true, get: function () { return v1p1beta1_1.cloudasset_v1p1beta1; } });
var v1p4beta1_1 = require("./apis/cloudasset/v1p4beta1");
Object.defineProperty(exports, "cloudasset_v1p4beta1", { enumerable: true, get: function () { return v1p4beta1_1.cloudasset_v1p4beta1; } });
var v1p5beta1_1 = require("./apis/cloudasset/v1p5beta1");
Object.defineProperty(exports, "cloudasset_v1p5beta1", { enumerable: true, get: function () { return v1p5beta1_1.cloudasset_v1p5beta1; } });
var v1p7beta1_1 = require("./apis/cloudasset/v1p7beta1");
Object.defineProperty(exports, "cloudasset_v1p7beta1", { enumerable: true, get: function () { return v1p7beta1_1.cloudasset_v1p7beta1; } });
var v1_49 = require("./apis/cloudbilling/v1");
Object.defineProperty(exports, "cloudbilling_v1", { enumerable: true, get: function () { return v1_49.cloudbilling_v1; } });
var v1beta_8 = require("./apis/cloudbilling/v1beta");
Object.defineProperty(exports, "cloudbilling_v1beta", { enumerable: true, get: function () { return v1beta_8.cloudbilling_v1beta; } });
var v1_50 = require("./apis/cloudbuild/v1");
Object.defineProperty(exports, "cloudbuild_v1", { enumerable: true, get: function () { return v1_50.cloudbuild_v1; } });
var v1alpha1_3 = require("./apis/cloudbuild/v1alpha1");
Object.defineProperty(exports, "cloudbuild_v1alpha1", { enumerable: true, get: function () { return v1alpha1_3.cloudbuild_v1alpha1; } });
var v1alpha2_2 = require("./apis/cloudbuild/v1alpha2");
Object.defineProperty(exports, "cloudbuild_v1alpha2", { enumerable: true, get: function () { return v1alpha2_2.cloudbuild_v1alpha2; } });
var v1beta1_12 = require("./apis/cloudbuild/v1beta1");
Object.defineProperty(exports, "cloudbuild_v1beta1", { enumerable: true, get: function () { return v1beta1_12.cloudbuild_v1beta1; } });
var v2_9 = require("./apis/cloudbuild/v2");
Object.defineProperty(exports, "cloudbuild_v2", { enumerable: true, get: function () { return v2_9.cloudbuild_v2; } });
var v1_51 = require("./apis/cloudchannel/v1");
Object.defineProperty(exports, "cloudchannel_v1", { enumerable: true, get: function () { return v1_51.cloudchannel_v1; } });
var v1_52 = require("./apis/cloudcontrolspartner/v1");
Object.defineProperty(exports, "cloudcontrolspartner_v1", { enumerable: true, get: function () { return v1_52.cloudcontrolspartner_v1; } });
var v1beta_9 = require("./apis/cloudcontrolspartner/v1beta");
Object.defineProperty(exports, "cloudcontrolspartner_v1beta", { enumerable: true, get: function () { return v1beta_9.cloudcontrolspartner_v1beta; } });
var v2_10 = require("./apis/clouddebugger/v2");
Object.defineProperty(exports, "clouddebugger_v2", { enumerable: true, get: function () { return v2_10.clouddebugger_v2; } });
var v1_53 = require("./apis/clouddeploy/v1");
Object.defineProperty(exports, "clouddeploy_v1", { enumerable: true, get: function () { return v1_53.clouddeploy_v1; } });
var v1beta1_13 = require("./apis/clouderrorreporting/v1beta1");
Object.defineProperty(exports, "clouderrorreporting_v1beta1", { enumerable: true, get: function () { return v1beta1_13.clouderrorreporting_v1beta1; } });
var v1_54 = require("./apis/cloudfunctions/v1");
Object.defineProperty(exports, "cloudfunctions_v1", { enumerable: true, get: function () { return v1_54.cloudfunctions_v1; } });
var v1beta2_2 = require("./apis/cloudfunctions/v1beta2");
Object.defineProperty(exports, "cloudfunctions_v1beta2", { enumerable: true, get: function () { return v1beta2_2.cloudfunctions_v1beta2; } });
var v2_11 = require("./apis/cloudfunctions/v2");
Object.defineProperty(exports, "cloudfunctions_v2", { enumerable: true, get: function () { return v2_11.cloudfunctions_v2; } });
var v2alpha_1 = require("./apis/cloudfunctions/v2alpha");
Object.defineProperty(exports, "cloudfunctions_v2alpha", { enumerable: true, get: function () { return v2alpha_1.cloudfunctions_v2alpha; } });
var v2beta_1 = require("./apis/cloudfunctions/v2beta");
Object.defineProperty(exports, "cloudfunctions_v2beta", { enumerable: true, get: function () { return v2beta_1.cloudfunctions_v2beta; } });
var v1_55 = require("./apis/cloudidentity/v1");
Object.defineProperty(exports, "cloudidentity_v1", { enumerable: true, get: function () { return v1_55.cloudidentity_v1; } });
var v1beta1_14 = require("./apis/cloudidentity/v1beta1");
Object.defineProperty(exports, "cloudidentity_v1beta1", { enumerable: true, get: function () { return v1beta1_14.cloudidentity_v1beta1; } });
var v1_56 = require("./apis/cloudiot/v1");
Object.defineProperty(exports, "cloudiot_v1", { enumerable: true, get: function () { return v1_56.cloudiot_v1; } });
var v1_57 = require("./apis/cloudkms/v1");
Object.defineProperty(exports, "cloudkms_v1", { enumerable: true, get: function () { return v1_57.cloudkms_v1; } });
var v2_12 = require("./apis/cloudprofiler/v2");
Object.defineProperty(exports, "cloudprofiler_v2", { enumerable: true, get: function () { return v2_12.cloudprofiler_v2; } });
var v1_58 = require("./apis/cloudresourcemanager/v1");
Object.defineProperty(exports, "cloudresourcemanager_v1", { enumerable: true, get: function () { return v1_58.cloudresourcemanager_v1; } });
var v1beta1_15 = require("./apis/cloudresourcemanager/v1beta1");
Object.defineProperty(exports, "cloudresourcemanager_v1beta1", { enumerable: true, get: function () { return v1beta1_15.cloudresourcemanager_v1beta1; } });
var v2_13 = require("./apis/cloudresourcemanager/v2");
Object.defineProperty(exports, "cloudresourcemanager_v2", { enumerable: true, get: function () { return v2_13.cloudresourcemanager_v2; } });
var v2beta1_2 = require("./apis/cloudresourcemanager/v2beta1");
Object.defineProperty(exports, "cloudresourcemanager_v2beta1", { enumerable: true, get: function () { return v2beta1_2.cloudresourcemanager_v2beta1; } });
var v3_5 = require("./apis/cloudresourcemanager/v3");
Object.defineProperty(exports, "cloudresourcemanager_v3", { enumerable: true, get: function () { return v3_5.cloudresourcemanager_v3; } });
var v1_59 = require("./apis/cloudscheduler/v1");
Object.defineProperty(exports, "cloudscheduler_v1", { enumerable: true, get: function () { return v1_59.cloudscheduler_v1; } });
var v1beta1_16 = require("./apis/cloudscheduler/v1beta1");
Object.defineProperty(exports, "cloudscheduler_v1beta1", { enumerable: true, get: function () { return v1beta1_16.cloudscheduler_v1beta1; } });
var v1_60 = require("./apis/cloudsearch/v1");
Object.defineProperty(exports, "cloudsearch_v1", { enumerable: true, get: function () { return v1_60.cloudsearch_v1; } });
var v1_61 = require("./apis/cloudshell/v1");
Object.defineProperty(exports, "cloudshell_v1", { enumerable: true, get: function () { return v1_61.cloudshell_v1; } });
var v1alpha1_4 = require("./apis/cloudshell/v1alpha1");
Object.defineProperty(exports, "cloudshell_v1alpha1", { enumerable: true, get: function () { return v1alpha1_4.cloudshell_v1alpha1; } });
var v2_14 = require("./apis/cloudsupport/v2");
Object.defineProperty(exports, "cloudsupport_v2", { enumerable: true, get: function () { return v2_14.cloudsupport_v2; } });
var v2beta_2 = require("./apis/cloudsupport/v2beta");
Object.defineProperty(exports, "cloudsupport_v2beta", { enumerable: true, get: function () { return v2beta_2.cloudsupport_v2beta; } });
var v2_15 = require("./apis/cloudtasks/v2");
Object.defineProperty(exports, "cloudtasks_v2", { enumerable: true, get: function () { return v2_15.cloudtasks_v2; } });
var v2beta2_1 = require("./apis/cloudtasks/v2beta2");
Object.defineProperty(exports, "cloudtasks_v2beta2", { enumerable: true, get: function () { return v2beta2_1.cloudtasks_v2beta2; } });
var v2beta3_1 = require("./apis/cloudtasks/v2beta3");
Object.defineProperty(exports, "cloudtasks_v2beta3", { enumerable: true, get: function () { return v2beta3_1.cloudtasks_v2beta3; } });
var v1_62 = require("./apis/cloudtrace/v1");
Object.defineProperty(exports, "cloudtrace_v1", { enumerable: true, get: function () { return v1_62.cloudtrace_v1; } });
var v2_16 = require("./apis/cloudtrace/v2");
Object.defineProperty(exports, "cloudtrace_v2", { enumerable: true, get: function () { return v2_16.cloudtrace_v2; } });
var v2beta1_3 = require("./apis/cloudtrace/v2beta1");
Object.defineProperty(exports, "cloudtrace_v2beta1", { enumerable: true, get: function () { return v2beta1_3.cloudtrace_v2beta1; } });
var v1_63 = require("./apis/composer/v1");
Object.defineProperty(exports, "composer_v1", { enumerable: true, get: function () { return v1_63.composer_v1; } });
var v1beta1_17 = require("./apis/composer/v1beta1");
Object.defineProperty(exports, "composer_v1beta1", { enumerable: true, get: function () { return v1beta1_17.composer_v1beta1; } });
var alpha_1 = require("./apis/compute/alpha");
Object.defineProperty(exports, "compute_alpha", { enumerable: true, get: function () { return alpha_1.compute_alpha; } });
var beta_1 = require("./apis/compute/beta");
Object.defineProperty(exports, "compute_beta", { enumerable: true, get: function () { return beta_1.compute_beta; } });
var v1_64 = require("./apis/compute/v1");
Object.defineProperty(exports, "compute_v1", { enumerable: true, get: function () { return v1_64.compute_v1; } });
var v1_65 = require("./apis/config/v1");
Object.defineProperty(exports, "config_v1", { enumerable: true, get: function () { return v1_65.config_v1; } });
var v1_66 = require("./apis/connectors/v1");
Object.defineProperty(exports, "connectors_v1", { enumerable: true, get: function () { return v1_66.connectors_v1; } });
var v2_17 = require("./apis/connectors/v2");
Object.defineProperty(exports, "connectors_v2", { enumerable: true, get: function () { return v2_17.connectors_v2; } });
var v1alpha1_5 = require("./apis/contactcenteraiplatform/v1alpha1");
Object.defineProperty(exports, "contactcenteraiplatform_v1alpha1", { enumerable: true, get: function () { return v1alpha1_5.contactcenteraiplatform_v1alpha1; } });
var v1_67 = require("./apis/contactcenterinsights/v1");
Object.defineProperty(exports, "contactcenterinsights_v1", { enumerable: true, get: function () { return v1_67.contactcenterinsights_v1; } });
var v1_68 = require("./apis/container/v1");
Object.defineProperty(exports, "container_v1", { enumerable: true, get: function () { return v1_68.container_v1; } });
var v1beta1_18 = require("./apis/container/v1beta1");
Object.defineProperty(exports, "container_v1beta1", { enumerable: true, get: function () { return v1beta1_18.container_v1beta1; } });
var v1_69 = require("./apis/containeranalysis/v1");
Object.defineProperty(exports, "containeranalysis_v1", { enumerable: true, get: function () { return v1_69.containeranalysis_v1; } });
var v1alpha1_6 = require("./apis/containeranalysis/v1alpha1");
Object.defineProperty(exports, "containeranalysis_v1alpha1", { enumerable: true, get: function () { return v1alpha1_6.containeranalysis_v1alpha1; } });
var v1beta1_19 = require("./apis/containeranalysis/v1beta1");
Object.defineProperty(exports, "containeranalysis_v1beta1", { enumerable: true, get: function () { return v1beta1_19.containeranalysis_v1beta1; } });
var v2_1_1 = require("./apis/content/v2.1");
Object.defineProperty(exports, "content_v2_1", { enumerable: true, get: function () { return v2_1_1.content_v2_1; } });
var v2_18 = require("./apis/content/v2");
Object.defineProperty(exports, "content_v2", { enumerable: true, get: function () { return v2_18.content_v2; } });
var v1_70 = require("./apis/contentwarehouse/v1");
Object.defineProperty(exports, "contentwarehouse_v1", { enumerable: true, get: function () { return v1_70.contentwarehouse_v1; } });
var v1_71 = require("./apis/css/v1");
Object.defineProperty(exports, "css_v1", { enumerable: true, get: function () { return v1_71.css_v1; } });
var v1_72 = require("./apis/customsearch/v1");
Object.defineProperty(exports, "customsearch_v1", { enumerable: true, get: function () { return v1_72.customsearch_v1; } });
var v1_73 = require("./apis/datacatalog/v1");
Object.defineProperty(exports, "datacatalog_v1", { enumerable: true, get: function () { return v1_73.datacatalog_v1; } });
var v1beta1_20 = require("./apis/datacatalog/v1beta1");
Object.defineProperty(exports, "datacatalog_v1beta1", { enumerable: true, get: function () { return v1beta1_20.datacatalog_v1beta1; } });
var v1b3_1 = require("./apis/dataflow/v1b3");
Object.defineProperty(exports, "dataflow_v1b3", { enumerable: true, get: function () { return v1b3_1.dataflow_v1b3; } });
var v1beta1_21 = require("./apis/dataform/v1beta1");
Object.defineProperty(exports, "dataform_v1beta1", { enumerable: true, get: function () { return v1beta1_21.dataform_v1beta1; } });
var v1_74 = require("./apis/datafusion/v1");
Object.defineProperty(exports, "datafusion_v1", { enumerable: true, get: function () { return v1_74.datafusion_v1; } });
var v1beta1_22 = require("./apis/datafusion/v1beta1");
Object.defineProperty(exports, "datafusion_v1beta1", { enumerable: true, get: function () { return v1beta1_22.datafusion_v1beta1; } });
var v1beta1_23 = require("./apis/datalabeling/v1beta1");
Object.defineProperty(exports, "datalabeling_v1beta1", { enumerable: true, get: function () { return v1beta1_23.datalabeling_v1beta1; } });
var v1_75 = require("./apis/datalineage/v1");
Object.defineProperty(exports, "datalineage_v1", { enumerable: true, get: function () { return v1_75.datalineage_v1; } });
var v1_76 = require("./apis/datamigration/v1");
Object.defineProperty(exports, "datamigration_v1", { enumerable: true, get: function () { return v1_76.datamigration_v1; } });
var v1beta1_24 = require("./apis/datamigration/v1beta1");
Object.defineProperty(exports, "datamigration_v1beta1", { enumerable: true, get: function () { return v1beta1_24.datamigration_v1beta1; } });
var v1_77 = require("./apis/datapipelines/v1");
Object.defineProperty(exports, "datapipelines_v1", { enumerable: true, get: function () { return v1_77.datapipelines_v1; } });
var v1_78 = require("./apis/dataplex/v1");
Object.defineProperty(exports, "dataplex_v1", { enumerable: true, get: function () { return v1_78.dataplex_v1; } });
var v1_79 = require("./apis/dataportability/v1");
Object.defineProperty(exports, "dataportability_v1", { enumerable: true, get: function () { return v1_79.dataportability_v1; } });
var v1beta_10 = require("./apis/dataportability/v1beta");
Object.defineProperty(exports, "dataportability_v1beta", { enumerable: true, get: function () { return v1beta_10.dataportability_v1beta; } });
var v1_80 = require("./apis/dataproc/v1");
Object.defineProperty(exports, "dataproc_v1", { enumerable: true, get: function () { return v1_80.dataproc_v1; } });
var v1beta2_3 = require("./apis/dataproc/v1beta2");
Object.defineProperty(exports, "dataproc_v1beta2", { enumerable: true, get: function () { return v1beta2_3.dataproc_v1beta2; } });
var v1_81 = require("./apis/datastore/v1");
Object.defineProperty(exports, "datastore_v1", { enumerable: true, get: function () { return v1_81.datastore_v1; } });
var v1beta1_25 = require("./apis/datastore/v1beta1");
Object.defineProperty(exports, "datastore_v1beta1", { enumerable: true, get: function () { return v1beta1_25.datastore_v1beta1; } });
var v1beta3_1 = require("./apis/datastore/v1beta3");
Object.defineProperty(exports, "datastore_v1beta3", { enumerable: true, get: function () { return v1beta3_1.datastore_v1beta3; } });
var v1_82 = require("./apis/datastream/v1");
Object.defineProperty(exports, "datastream_v1", { enumerable: true, get: function () { return v1_82.datastream_v1; } });
var v1alpha1_7 = require("./apis/datastream/v1alpha1");
Object.defineProperty(exports, "datastream_v1alpha1", { enumerable: true, get: function () { return v1alpha1_7.datastream_v1alpha1; } });
var alpha_2 = require("./apis/deploymentmanager/alpha");
Object.defineProperty(exports, "deploymentmanager_alpha", { enumerable: true, get: function () { return alpha_2.deploymentmanager_alpha; } });
var v2_19 = require("./apis/deploymentmanager/v2");
Object.defineProperty(exports, "deploymentmanager_v2", { enumerable: true, get: function () { return v2_19.deploymentmanager_v2; } });
var v2beta_3 = require("./apis/deploymentmanager/v2beta");
Object.defineProperty(exports, "deploymentmanager_v2beta", { enumerable: true, get: function () { return v2beta_3.deploymentmanager_v2beta; } });
var v1_83 = require("./apis/developerconnect/v1");
Object.defineProperty(exports, "developerconnect_v1", { enumerable: true, get: function () { return v1_83.developerconnect_v1; } });
var v3_3_1 = require("./apis/dfareporting/v3.3");
Object.defineProperty(exports, "dfareporting_v3_3", { enumerable: true, get: function () { return v3_3_1.dfareporting_v3_3; } });
var v3_4_1 = require("./apis/dfareporting/v3.4");
Object.defineProperty(exports, "dfareporting_v3_4", { enumerable: true, get: function () { return v3_4_1.dfareporting_v3_4; } });
var v3_5_1 = require("./apis/dfareporting/v3.5");
Object.defineProperty(exports, "dfareporting_v3_5", { enumerable: true, get: function () { return v3_5_1.dfareporting_v3_5; } });
var v4_2 = require("./apis/dfareporting/v4");
Object.defineProperty(exports, "dfareporting_v4", { enumerable: true, get: function () { return v4_2.dfareporting_v4; } });
var v2_20 = require("./apis/dialogflow/v2");
Object.defineProperty(exports, "dialogflow_v2", { enumerable: true, get: function () { return v2_20.dialogflow_v2; } });
var v2beta1_4 = require("./apis/dialogflow/v2beta1");
Object.defineProperty(exports, "dialogflow_v2beta1", { enumerable: true, get: function () { return v2beta1_4.dialogflow_v2beta1; } });
var v3_6 = require("./apis/dialogflow/v3");
Object.defineProperty(exports, "dialogflow_v3", { enumerable: true, get: function () { return v3_6.dialogflow_v3; } });
var v3beta1_1 = require("./apis/dialogflow/v3beta1");
Object.defineProperty(exports, "dialogflow_v3beta1", { enumerable: true, get: function () { return v3beta1_1.dialogflow_v3beta1; } });
var v1_84 = require("./apis/digitalassetlinks/v1");
Object.defineProperty(exports, "digitalassetlinks_v1", { enumerable: true, get: function () { return v1_84.digitalassetlinks_v1; } });
var v1_85 = require("./apis/discovery/v1");
Object.defineProperty(exports, "discovery_v1", { enumerable: true, get: function () { return v1_85.discovery_v1; } });
var v1_86 = require("./apis/discoveryengine/v1");
Object.defineProperty(exports, "discoveryengine_v1", { enumerable: true, get: function () { return v1_86.discoveryengine_v1; } });
var v1alpha_11 = require("./apis/discoveryengine/v1alpha");
Object.defineProperty(exports, "discoveryengine_v1alpha", { enumerable: true, get: function () { return v1alpha_11.discoveryengine_v1alpha; } });
var v1beta_11 = require("./apis/discoveryengine/v1beta");
Object.defineProperty(exports, "discoveryengine_v1beta", { enumerable: true, get: function () { return v1beta_11.discoveryengine_v1beta; } });
var v1_87 = require("./apis/displayvideo/v1");
Object.defineProperty(exports, "displayvideo_v1", { enumerable: true, get: function () { return v1_87.displayvideo_v1; } });
var v1beta_12 = require("./apis/displayvideo/v1beta");
Object.defineProperty(exports, "displayvideo_v1beta", { enumerable: true, get: function () { return v1beta_12.displayvideo_v1beta; } });
var v1beta2_4 = require("./apis/displayvideo/v1beta2");
Object.defineProperty(exports, "displayvideo_v1beta2", { enumerable: true, get: function () { return v1beta2_4.displayvideo_v1beta2; } });
var v1dev_1 = require("./apis/displayvideo/v1dev");
Object.defineProperty(exports, "displayvideo_v1dev", { enumerable: true, get: function () { return v1dev_1.displayvideo_v1dev; } });
var v2_21 = require("./apis/displayvideo/v2");
Object.defineProperty(exports, "displayvideo_v2", { enumerable: true, get: function () { return v2_21.displayvideo_v2; } });
var v3_7 = require("./apis/displayvideo/v3");
Object.defineProperty(exports, "displayvideo_v3", { enumerable: true, get: function () { return v3_7.displayvideo_v3; } });
var v4_3 = require("./apis/displayvideo/v4");
Object.defineProperty(exports, "displayvideo_v4", { enumerable: true, get: function () { return v4_3.displayvideo_v4; } });
var v2_22 = require("./apis/dlp/v2");
Object.defineProperty(exports, "dlp_v2", { enumerable: true, get: function () { return v2_22.dlp_v2; } });
var v1_88 = require("./apis/dns/v1");
Object.defineProperty(exports, "dns_v1", { enumerable: true, get: function () { return v1_88.dns_v1; } });
var v1beta2_5 = require("./apis/dns/v1beta2");
Object.defineProperty(exports, "dns_v1beta2", { enumerable: true, get: function () { return v1beta2_5.dns_v1beta2; } });
var v2_23 = require("./apis/dns/v2");
Object.defineProperty(exports, "dns_v2", { enumerable: true, get: function () { return v2_23.dns_v2; } });
var v2beta1_5 = require("./apis/dns/v2beta1");
Object.defineProperty(exports, "dns_v2beta1", { enumerable: true, get: function () { return v2beta1_5.dns_v2beta1; } });
var v1_89 = require("./apis/docs/v1");
Object.defineProperty(exports, "docs_v1", { enumerable: true, get: function () { return v1_89.docs_v1; } });
var v1_90 = require("./apis/documentai/v1");
Object.defineProperty(exports, "documentai_v1", { enumerable: true, get: function () { return v1_90.documentai_v1; } });
var v1beta2_6 = require("./apis/documentai/v1beta2");
Object.defineProperty(exports, "documentai_v1beta2", { enumerable: true, get: function () { return v1beta2_6.documentai_v1beta2; } });
var v1beta3_2 = require("./apis/documentai/v1beta3");
Object.defineProperty(exports, "documentai_v1beta3", { enumerable: true, get: function () { return v1beta3_2.documentai_v1beta3; } });
var v1_91 = require("./apis/domains/v1");
Object.defineProperty(exports, "domains_v1", { enumerable: true, get: function () { return v1_91.domains_v1; } });
var v1alpha2_3 = require("./apis/domains/v1alpha2");
Object.defineProperty(exports, "domains_v1alpha2", { enumerable: true, get: function () { return v1alpha2_3.domains_v1alpha2; } });
var v1beta1_26 = require("./apis/domains/v1beta1");
Object.defineProperty(exports, "domains_v1beta1", { enumerable: true, get: function () { return v1beta1_26.domains_v1beta1; } });
var v1_92 = require("./apis/domainsrdap/v1");
Object.defineProperty(exports, "domainsrdap_v1", { enumerable: true, get: function () { return v1_92.domainsrdap_v1; } });
var v1_1_2 = require("./apis/doubleclickbidmanager/v1.1");
Object.defineProperty(exports, "doubleclickbidmanager_v1_1", { enumerable: true, get: function () { return v1_1_2.doubleclickbidmanager_v1_1; } });
var v1_93 = require("./apis/doubleclickbidmanager/v1");
Object.defineProperty(exports, "doubleclickbidmanager_v1", { enumerable: true, get: function () { return v1_93.doubleclickbidmanager_v1; } });
var v2_24 = require("./apis/doubleclickbidmanager/v2");
Object.defineProperty(exports, "doubleclickbidmanager_v2", { enumerable: true, get: function () { return v2_24.doubleclickbidmanager_v2; } });
var v2_25 = require("./apis/doubleclicksearch/v2");
Object.defineProperty(exports, "doubleclicksearch_v2", { enumerable: true, get: function () { return v2_25.doubleclicksearch_v2; } });
var v2_26 = require("./apis/drive/v2");
Object.defineProperty(exports, "drive_v2", { enumerable: true, get: function () { return v2_26.drive_v2; } });
var v3_8 = require("./apis/drive/v3");
Object.defineProperty(exports, "drive_v3", { enumerable: true, get: function () { return v3_8.drive_v3; } });
var v2_27 = require("./apis/driveactivity/v2");
Object.defineProperty(exports, "driveactivity_v2", { enumerable: true, get: function () { return v2_27.driveactivity_v2; } });
var v2_28 = require("./apis/drivelabels/v2");
Object.defineProperty(exports, "drivelabels_v2", { enumerable: true, get: function () { return v2_28.drivelabels_v2; } });
var v2beta_4 = require("./apis/drivelabels/v2beta");
Object.defineProperty(exports, "drivelabels_v2beta", { enumerable: true, get: function () { return v2beta_4.drivelabels_v2beta; } });
var v1_94 = require("./apis/essentialcontacts/v1");
Object.defineProperty(exports, "essentialcontacts_v1", { enumerable: true, get: function () { return v1_94.essentialcontacts_v1; } });
var v1_95 = require("./apis/eventarc/v1");
Object.defineProperty(exports, "eventarc_v1", { enumerable: true, get: function () { return v1_95.eventarc_v1; } });
var v1beta1_27 = require("./apis/eventarc/v1beta1");
Object.defineProperty(exports, "eventarc_v1beta1", { enumerable: true, get: function () { return v1beta1_27.eventarc_v1beta1; } });
var v1alpha1_8 = require("./apis/factchecktools/v1alpha1");
Object.defineProperty(exports, "factchecktools_v1alpha1", { enumerable: true, get: function () { return v1alpha1_8.factchecktools_v1alpha1; } });
var v1_96 = require("./apis/fcm/v1");
Object.defineProperty(exports, "fcm_v1", { enumerable: true, get: function () { return v1_96.fcm_v1; } });
var v1beta1_28 = require("./apis/fcmdata/v1beta1");
Object.defineProperty(exports, "fcmdata_v1beta1", { enumerable: true, get: function () { return v1beta1_28.fcmdata_v1beta1; } });
var v1_97 = require("./apis/file/v1");
Object.defineProperty(exports, "file_v1", { enumerable: true, get: function () { return v1_97.file_v1; } });
var v1beta1_29 = require("./apis/file/v1beta1");
Object.defineProperty(exports, "file_v1beta1", { enumerable: true, get: function () { return v1beta1_29.file_v1beta1; } });
var v1beta1_30 = require("./apis/firebase/v1beta1");
Object.defineProperty(exports, "firebase_v1beta1", { enumerable: true, get: function () { return v1beta1_30.firebase_v1beta1; } });
var v1_98 = require("./apis/firebaseappcheck/v1");
Object.defineProperty(exports, "firebaseappcheck_v1", { enumerable: true, get: function () { return v1_98.firebaseappcheck_v1; } });
var v1beta_13 = require("./apis/firebaseappcheck/v1beta");
Object.defineProperty(exports, "firebaseappcheck_v1beta", { enumerable: true, get: function () { return v1beta_13.firebaseappcheck_v1beta; } });
var v1_99 = require("./apis/firebaseappdistribution/v1");
Object.defineProperty(exports, "firebaseappdistribution_v1", { enumerable: true, get: function () { return v1_99.firebaseappdistribution_v1; } });
var v1alpha_12 = require("./apis/firebaseappdistribution/v1alpha");
Object.defineProperty(exports, "firebaseappdistribution_v1alpha", { enumerable: true, get: function () { return v1alpha_12.firebaseappdistribution_v1alpha; } });
var v1beta_14 = require("./apis/firebasedatabase/v1beta");
Object.defineProperty(exports, "firebasedatabase_v1beta", { enumerable: true, get: function () { return v1beta_14.firebasedatabase_v1beta; } });
var v1_100 = require("./apis/firebasedynamiclinks/v1");
Object.defineProperty(exports, "firebasedynamiclinks_v1", { enumerable: true, get: function () { return v1_100.firebasedynamiclinks_v1; } });
var v1_101 = require("./apis/firebasehosting/v1");
Object.defineProperty(exports, "firebasehosting_v1", { enumerable: true, get: function () { return v1_101.firebasehosting_v1; } });
var v1beta1_31 = require("./apis/firebasehosting/v1beta1");
Object.defineProperty(exports, "firebasehosting_v1beta1", { enumerable: true, get: function () { return v1beta1_31.firebasehosting_v1beta1; } });
var v1_102 = require("./apis/firebaseml/v1");
Object.defineProperty(exports, "firebaseml_v1", { enumerable: true, get: function () { return v1_102.firebaseml_v1; } });
var v1beta2_7 = require("./apis/firebaseml/v1beta2");
Object.defineProperty(exports, "firebaseml_v1beta2", { enumerable: true, get: function () { return v1beta2_7.firebaseml_v1beta2; } });
var v2beta_5 = require("./apis/firebaseml/v2beta");
Object.defineProperty(exports, "firebaseml_v2beta", { enumerable: true, get: function () { return v2beta_5.firebaseml_v2beta; } });
var v1_103 = require("./apis/firebaserules/v1");
Object.defineProperty(exports, "firebaserules_v1", { enumerable: true, get: function () { return v1_103.firebaserules_v1; } });
var v1beta_15 = require("./apis/firebasestorage/v1beta");
Object.defineProperty(exports, "firebasestorage_v1beta", { enumerable: true, get: function () { return v1beta_15.firebasestorage_v1beta; } });
var v1_104 = require("./apis/firestore/v1");
Object.defineProperty(exports, "firestore_v1", { enumerable: true, get: function () { return v1_104.firestore_v1; } });
var v1beta1_32 = require("./apis/firestore/v1beta1");
Object.defineProperty(exports, "firestore_v1beta1", { enumerable: true, get: function () { return v1beta1_32.firestore_v1beta1; } });
var v1beta2_8 = require("./apis/firestore/v1beta2");
Object.defineProperty(exports, "firestore_v1beta2", { enumerable: true, get: function () { return v1beta2_8.firestore_v1beta2; } });
var v1_105 = require("./apis/fitness/v1");
Object.defineProperty(exports, "fitness_v1", { enumerable: true, get: function () { return v1_105.fitness_v1; } });
var v1_106 = require("./apis/forms/v1");
Object.defineProperty(exports, "forms_v1", { enumerable: true, get: function () { return v1_106.forms_v1; } });
var v1_107 = require("./apis/games/v1");
Object.defineProperty(exports, "games_v1", { enumerable: true, get: function () { return v1_107.games_v1; } });
var v1configuration_1 = require("./apis/gamesConfiguration/v1configuration");
Object.defineProperty(exports, "gamesConfiguration_v1configuration", { enumerable: true, get: function () { return v1configuration_1.gamesConfiguration_v1configuration; } });
var v1management_1 = require("./apis/gamesManagement/v1management");
Object.defineProperty(exports, "gamesManagement_v1management", { enumerable: true, get: function () { return v1management_1.gamesManagement_v1management; } });
var v1_108 = require("./apis/gameservices/v1");
Object.defineProperty(exports, "gameservices_v1", { enumerable: true, get: function () { return v1_108.gameservices_v1; } });
var v1beta_16 = require("./apis/gameservices/v1beta");
Object.defineProperty(exports, "gameservices_v1beta", { enumerable: true, get: function () { return v1beta_16.gameservices_v1beta; } });
var v1_109 = require("./apis/genomics/v1");
Object.defineProperty(exports, "genomics_v1", { enumerable: true, get: function () { return v1_109.genomics_v1; } });
var v1alpha2_4 = require("./apis/genomics/v1alpha2");
Object.defineProperty(exports, "genomics_v1alpha2", { enumerable: true, get: function () { return v1alpha2_4.genomics_v1alpha2; } });
var v2alpha1_1 = require("./apis/genomics/v2alpha1");
Object.defineProperty(exports, "genomics_v2alpha1", { enumerable: true, get: function () { return v2alpha1_1.genomics_v2alpha1; } });
var v1_110 = require("./apis/gkebackup/v1");
Object.defineProperty(exports, "gkebackup_v1", { enumerable: true, get: function () { return v1_110.gkebackup_v1; } });
var v1_111 = require("./apis/gkehub/v1");
Object.defineProperty(exports, "gkehub_v1", { enumerable: true, get: function () { return v1_111.gkehub_v1; } });
var v1alpha_13 = require("./apis/gkehub/v1alpha");
Object.defineProperty(exports, "gkehub_v1alpha", { enumerable: true, get: function () { return v1alpha_13.gkehub_v1alpha; } });
var v1alpha2_5 = require("./apis/gkehub/v1alpha2");
Object.defineProperty(exports, "gkehub_v1alpha2", { enumerable: true, get: function () { return v1alpha2_5.gkehub_v1alpha2; } });
var v1beta_17 = require("./apis/gkehub/v1beta");
Object.defineProperty(exports, "gkehub_v1beta", { enumerable: true, get: function () { return v1beta_17.gkehub_v1beta; } });
var v1beta1_33 = require("./apis/gkehub/v1beta1");
Object.defineProperty(exports, "gkehub_v1beta1", { enumerable: true, get: function () { return v1beta1_33.gkehub_v1beta1; } });
var v2_29 = require("./apis/gkehub/v2");
Object.defineProperty(exports, "gkehub_v2", { enumerable: true, get: function () { return v2_29.gkehub_v2; } });
var v2alpha_2 = require("./apis/gkehub/v2alpha");
Object.defineProperty(exports, "gkehub_v2alpha", { enumerable: true, get: function () { return v2alpha_2.gkehub_v2alpha; } });
var v2beta_6 = require("./apis/gkehub/v2beta");
Object.defineProperty(exports, "gkehub_v2beta", { enumerable: true, get: function () { return v2beta_6.gkehub_v2beta; } });
var v1_112 = require("./apis/gkeonprem/v1");
Object.defineProperty(exports, "gkeonprem_v1", { enumerable: true, get: function () { return v1_112.gkeonprem_v1; } });
var v1_113 = require("./apis/gmail/v1");
Object.defineProperty(exports, "gmail_v1", { enumerable: true, get: function () { return v1_113.gmail_v1; } });
var v1_114 = require("./apis/gmailpostmastertools/v1");
Object.defineProperty(exports, "gmailpostmastertools_v1", { enumerable: true, get: function () { return v1_114.gmailpostmastertools_v1; } });
var v1beta1_34 = require("./apis/gmailpostmastertools/v1beta1");
Object.defineProperty(exports, "gmailpostmastertools_v1beta1", { enumerable: true, get: function () { return v1beta1_34.gmailpostmastertools_v1beta1; } });
var v1_115 = require("./apis/groupsmigration/v1");
Object.defineProperty(exports, "groupsmigration_v1", { enumerable: true, get: function () { return v1_115.groupsmigration_v1; } });
var v1_116 = require("./apis/groupssettings/v1");
Object.defineProperty(exports, "groupssettings_v1", { enumerable: true, get: function () { return v1_116.groupssettings_v1; } });
var v1_117 = require("./apis/healthcare/v1");
Object.defineProperty(exports, "healthcare_v1", { enumerable: true, get: function () { return v1_117.healthcare_v1; } });
var v1beta1_35 = require("./apis/healthcare/v1beta1");
Object.defineProperty(exports, "healthcare_v1beta1", { enumerable: true, get: function () { return v1beta1_35.healthcare_v1beta1; } });
var v1_118 = require("./apis/homegraph/v1");
Object.defineProperty(exports, "homegraph_v1", { enumerable: true, get: function () { return v1_118.homegraph_v1; } });
var v1_119 = require("./apis/iam/v1");
Object.defineProperty(exports, "iam_v1", { enumerable: true, get: function () { return v1_119.iam_v1; } });
var v2_30 = require("./apis/iam/v2");
Object.defineProperty(exports, "iam_v2", { enumerable: true, get: function () { return v2_30.iam_v2; } });
var v2beta_7 = require("./apis/iam/v2beta");
Object.defineProperty(exports, "iam_v2beta", { enumerable: true, get: function () { return v2beta_7.iam_v2beta; } });
var v1_120 = require("./apis/iamcredentials/v1");
Object.defineProperty(exports, "iamcredentials_v1", { enumerable: true, get: function () { return v1_120.iamcredentials_v1; } });
var v1_121 = require("./apis/iap/v1");
Object.defineProperty(exports, "iap_v1", { enumerable: true, get: function () { return v1_121.iap_v1; } });
var v1beta1_36 = require("./apis/iap/v1beta1");
Object.defineProperty(exports, "iap_v1beta1", { enumerable: true, get: function () { return v1beta1_36.iap_v1beta1; } });
var v1alpha_14 = require("./apis/ideahub/v1alpha");
Object.defineProperty(exports, "ideahub_v1alpha", { enumerable: true, get: function () { return v1alpha_14.ideahub_v1alpha; } });
var v1beta_18 = require("./apis/ideahub/v1beta");
Object.defineProperty(exports, "ideahub_v1beta", { enumerable: true, get: function () { return v1beta_18.ideahub_v1beta; } });
var v2_31 = require("./apis/identitytoolkit/v2");
Object.defineProperty(exports, "identitytoolkit_v2", { enumerable: true, get: function () { return v2_31.identitytoolkit_v2; } });
var v3_9 = require("./apis/identitytoolkit/v3");
Object.defineProperty(exports, "identitytoolkit_v3", { enumerable: true, get: function () { return v3_9.identitytoolkit_v3; } });
var v1_122 = require("./apis/ids/v1");
Object.defineProperty(exports, "ids_v1", { enumerable: true, get: function () { return v1_122.ids_v1; } });
var v3_10 = require("./apis/indexing/v3");
Object.defineProperty(exports, "indexing_v3", { enumerable: true, get: function () { return v3_10.indexing_v3; } });
var v1alpha_15 = require("./apis/integrations/v1alpha");
Object.defineProperty(exports, "integrations_v1alpha", { enumerable: true, get: function () { return v1alpha_15.integrations_v1alpha; } });
var v2_32 = require("./apis/jobs/v2");
Object.defineProperty(exports, "jobs_v2", { enumerable: true, get: function () { return v2_32.jobs_v2; } });
var v3_11 = require("./apis/jobs/v3");
Object.defineProperty(exports, "jobs_v3", { enumerable: true, get: function () { return v3_11.jobs_v3; } });
var v3p1beta1_1 = require("./apis/jobs/v3p1beta1");
Object.defineProperty(exports, "jobs_v3p1beta1", { enumerable: true, get: function () { return v3p1beta1_1.jobs_v3p1beta1; } });
var v4_4 = require("./apis/jobs/v4");
Object.defineProperty(exports, "jobs_v4", { enumerable: true, get: function () { return v4_4.jobs_v4; } });
var v1_123 = require("./apis/keep/v1");
Object.defineProperty(exports, "keep_v1", { enumerable: true, get: function () { return v1_123.keep_v1; } });
var v1_124 = require("./apis/kgsearch/v1");
Object.defineProperty(exports, "kgsearch_v1", { enumerable: true, get: function () { return v1_124.kgsearch_v1; } });
var v1_125 = require("./apis/kmsinventory/v1");
Object.defineProperty(exports, "kmsinventory_v1", { enumerable: true, get: function () { return v1_125.kmsinventory_v1; } });
var v1_126 = require("./apis/language/v1");
Object.defineProperty(exports, "language_v1", { enumerable: true, get: function () { return v1_126.language_v1; } });
var v1beta1_37 = require("./apis/language/v1beta1");
Object.defineProperty(exports, "language_v1beta1", { enumerable: true, get: function () { return v1beta1_37.language_v1beta1; } });
var v1beta2_9 = require("./apis/language/v1beta2");
Object.defineProperty(exports, "language_v1beta2", { enumerable: true, get: function () { return v1beta2_9.language_v1beta2; } });
var v2_33 = require("./apis/language/v2");
Object.defineProperty(exports, "language_v2", { enumerable: true, get: function () { return v2_33.language_v2; } });
var v1_127 = require("./apis/libraryagent/v1");
Object.defineProperty(exports, "libraryagent_v1", { enumerable: true, get: function () { return v1_127.libraryagent_v1; } });
var v1_128 = require("./apis/licensing/v1");
Object.defineProperty(exports, "licensing_v1", { enumerable: true, get: function () { return v1_128.licensing_v1; } });
var v2beta_8 = require("./apis/lifesciences/v2beta");
Object.defineProperty(exports, "lifesciences_v2beta", { enumerable: true, get: function () { return v2beta_8.lifesciences_v2beta; } });
var v1_129 = require("./apis/localservices/v1");
Object.defineProperty(exports, "localservices_v1", { enumerable: true, get: function () { return v1_129.localservices_v1; } });
var v2_34 = require("./apis/logging/v2");
Object.defineProperty(exports, "logging_v2", { enumerable: true, get: function () { return v2_34.logging_v2; } });
var v1_130 = require("./apis/looker/v1");
Object.defineProperty(exports, "looker_v1", { enumerable: true, get: function () { return v1_130.looker_v1; } });
var v1_131 = require("./apis/managedidentities/v1");
Object.defineProperty(exports, "managedidentities_v1", { enumerable: true, get: function () { return v1_131.managedidentities_v1; } });
var v1alpha1_9 = require("./apis/managedidentities/v1alpha1");
Object.defineProperty(exports, "managedidentities_v1alpha1", { enumerable: true, get: function () { return v1alpha1_9.managedidentities_v1alpha1; } });
var v1beta1_38 = require("./apis/managedidentities/v1beta1");
Object.defineProperty(exports, "managedidentities_v1beta1", { enumerable: true, get: function () { return v1beta1_38.managedidentities_v1beta1; } });
var v1_132 = require("./apis/manufacturers/v1");
Object.defineProperty(exports, "manufacturers_v1", { enumerable: true, get: function () { return v1_132.manufacturers_v1; } });
var v1alpha_16 = require("./apis/marketingplatformadmin/v1alpha");
Object.defineProperty(exports, "marketingplatformadmin_v1alpha", { enumerable: true, get: function () { return v1alpha_16.marketingplatformadmin_v1alpha; } });
var v2_35 = require("./apis/meet/v2");
Object.defineProperty(exports, "meet_v2", { enumerable: true, get: function () { return v2_35.meet_v2; } });
var v1_133 = require("./apis/memcache/v1");
Object.defineProperty(exports, "memcache_v1", { enumerable: true, get: function () { return v1_133.memcache_v1; } });
var v1beta2_10 = require("./apis/memcache/v1beta2");
Object.defineProperty(exports, "memcache_v1beta2", { enumerable: true, get: function () { return v1beta2_10.memcache_v1beta2; } });
var accounts_v1beta_1 = require("./apis/merchantapi/accounts_v1beta");
Object.defineProperty(exports, "merchantapi_accounts_v1beta", { enumerable: true, get: function () { return accounts_v1beta_1.merchantapi_accounts_v1beta; } });
var conversions_v1beta_1 = require("./apis/merchantapi/conversions_v1beta");
Object.defineProperty(exports, "merchantapi_conversions_v1beta", { enumerable: true, get: function () { return conversions_v1beta_1.merchantapi_conversions_v1beta; } });
var datasources_v1beta_1 = require("./apis/merchantapi/datasources_v1beta");
Object.defineProperty(exports, "merchantapi_datasources_v1beta", { enumerable: true, get: function () { return datasources_v1beta_1.merchantapi_datasources_v1beta; } });
var inventories_v1beta_1 = require("./apis/merchantapi/inventories_v1beta");
Object.defineProperty(exports, "merchantapi_inventories_v1beta", { enumerable: true, get: function () { return inventories_v1beta_1.merchantapi_inventories_v1beta; } });
var lfp_v1beta_1 = require("./apis/merchantapi/lfp_v1beta");
Object.defineProperty(exports, "merchantapi_lfp_v1beta", { enumerable: true, get: function () { return lfp_v1beta_1.merchantapi_lfp_v1beta; } });
var notifications_v1beta_1 = require("./apis/merchantapi/notifications_v1beta");
Object.defineProperty(exports, "merchantapi_notifications_v1beta", { enumerable: true, get: function () { return notifications_v1beta_1.merchantapi_notifications_v1beta; } });
var products_v1beta_1 = require("./apis/merchantapi/products_v1beta");
Object.defineProperty(exports, "merchantapi_products_v1beta", { enumerable: true, get: function () { return products_v1beta_1.merchantapi_products_v1beta; } });
var promotions_v1beta_1 = require("./apis/merchantapi/promotions_v1beta");
Object.defineProperty(exports, "merchantapi_promotions_v1beta", { enumerable: true, get: function () { return promotions_v1beta_1.merchantapi_promotions_v1beta; } });
var quota_v1beta_1 = require("./apis/merchantapi/quota_v1beta");
Object.defineProperty(exports, "merchantapi_quota_v1beta", { enumerable: true, get: function () { return quota_v1beta_1.merchantapi_quota_v1beta; } });
var reports_v1beta_1 = require("./apis/merchantapi/reports_v1beta");
Object.defineProperty(exports, "merchantapi_reports_v1beta", { enumerable: true, get: function () { return reports_v1beta_1.merchantapi_reports_v1beta; } });
var v1_134 = require("./apis/metastore/v1");
Object.defineProperty(exports, "metastore_v1", { enumerable: true, get: function () { return v1_134.metastore_v1; } });
var v1alpha_17 = require("./apis/metastore/v1alpha");
Object.defineProperty(exports, "metastore_v1alpha", { enumerable: true, get: function () { return v1alpha_17.metastore_v1alpha; } });
var v1beta_19 = require("./apis/metastore/v1beta");
Object.defineProperty(exports, "metastore_v1beta", { enumerable: true, get: function () { return v1beta_19.metastore_v1beta; } });
var v1_135 = require("./apis/migrationcenter/v1");
Object.defineProperty(exports, "migrationcenter_v1", { enumerable: true, get: function () { return v1_135.migrationcenter_v1; } });
var v1alpha1_10 = require("./apis/migrationcenter/v1alpha1");
Object.defineProperty(exports, "migrationcenter_v1alpha1", { enumerable: true, get: function () { return v1alpha1_10.migrationcenter_v1alpha1; } });
var v1_136 = require("./apis/ml/v1");
Object.defineProperty(exports, "ml_v1", { enumerable: true, get: function () { return v1_136.ml_v1; } });
var v1_137 = require("./apis/monitoring/v1");
Object.defineProperty(exports, "monitoring_v1", { enumerable: true, get: function () { return v1_137.monitoring_v1; } });
var v3_12 = require("./apis/monitoring/v3");
Object.defineProperty(exports, "monitoring_v3", { enumerable: true, get: function () { return v3_12.monitoring_v3; } });
var v1_138 = require("./apis/mybusinessaccountmanagement/v1");
Object.defineProperty(exports, "mybusinessaccountmanagement_v1", { enumerable: true, get: function () { return v1_138.mybusinessaccountmanagement_v1; } });
var v1_139 = require("./apis/mybusinessbusinesscalls/v1");
Object.defineProperty(exports, "mybusinessbusinesscalls_v1", { enumerable: true, get: function () { return v1_139.mybusinessbusinesscalls_v1; } });
var v1_140 = require("./apis/mybusinessbusinessinformation/v1");
Object.defineProperty(exports, "mybusinessbusinessinformation_v1", { enumerable: true, get: function () { return v1_140.mybusinessbusinessinformation_v1; } });
var v1_141 = require("./apis/mybusinesslodging/v1");
Object.defineProperty(exports, "mybusinesslodging_v1", { enumerable: true, get: function () { return v1_141.mybusinesslodging_v1; } });
var v1_142 = require("./apis/mybusinessnotifications/v1");
Object.defineProperty(exports, "mybusinessnotifications_v1", { enumerable: true, get: function () { return v1_142.mybusinessnotifications_v1; } });
var v1_143 = require("./apis/mybusinessplaceactions/v1");
Object.defineProperty(exports, "mybusinessplaceactions_v1", { enumerable: true, get: function () { return v1_143.mybusinessplaceactions_v1; } });
var v1_144 = require("./apis/mybusinessqanda/v1");
Object.defineProperty(exports, "mybusinessqanda_v1", { enumerable: true, get: function () { return v1_144.mybusinessqanda_v1; } });
var v1_145 = require("./apis/mybusinessverifications/v1");
Object.defineProperty(exports, "mybusinessverifications_v1", { enumerable: true, get: function () { return v1_145.mybusinessverifications_v1; } });
var v1_146 = require("./apis/networkconnectivity/v1");
Object.defineProperty(exports, "networkconnectivity_v1", { enumerable: true, get: function () { return v1_146.networkconnectivity_v1; } });
var v1alpha1_11 = require("./apis/networkconnectivity/v1alpha1");
Object.defineProperty(exports, "networkconnectivity_v1alpha1", { enumerable: true, get: function () { return v1alpha1_11.networkconnectivity_v1alpha1; } });
var v1_147 = require("./apis/networkmanagement/v1");
Object.defineProperty(exports, "networkmanagement_v1", { enumerable: true, get: function () { return v1_147.networkmanagement_v1; } });
var v1beta1_39 = require("./apis/networkmanagement/v1beta1");
Object.defineProperty(exports, "networkmanagement_v1beta1", { enumerable: true, get: function () { return v1beta1_39.networkmanagement_v1beta1; } });
var v1_148 = require("./apis/networksecurity/v1");
Object.defineProperty(exports, "networksecurity_v1", { enumerable: true, get: function () { return v1_148.networksecurity_v1; } });
var v1beta1_40 = require("./apis/networksecurity/v1beta1");
Object.defineProperty(exports, "networksecurity_v1beta1", { enumerable: true, get: function () { return v1beta1_40.networksecurity_v1beta1; } });
var v1_149 = require("./apis/networkservices/v1");
Object.defineProperty(exports, "networkservices_v1", { enumerable: true, get: function () { return v1_149.networkservices_v1; } });
var v1beta1_41 = require("./apis/networkservices/v1beta1");
Object.defineProperty(exports, "networkservices_v1beta1", { enumerable: true, get: function () { return v1beta1_41.networkservices_v1beta1; } });
var v1_150 = require("./apis/notebooks/v1");
Object.defineProperty(exports, "notebooks_v1", { enumerable: true, get: function () { return v1_150.notebooks_v1; } });
var v2_36 = require("./apis/notebooks/v2");
Object.defineProperty(exports, "notebooks_v2", { enumerable: true, get: function () { return v2_36.notebooks_v2; } });
var v2_37 = require("./apis/oauth2/v2");
Object.defineProperty(exports, "oauth2_v2", { enumerable: true, get: function () { return v2_37.oauth2_v2; } });
var v1_151 = require("./apis/ondemandscanning/v1");
Object.defineProperty(exports, "ondemandscanning_v1", { enumerable: true, get: function () { return v1_151.ondemandscanning_v1; } });
var v1beta1_42 = require("./apis/ondemandscanning/v1beta1");
Object.defineProperty(exports, "ondemandscanning_v1beta1", { enumerable: true, get: function () { return v1beta1_42.ondemandscanning_v1beta1; } });
var v2_38 = require("./apis/orgpolicy/v2");
Object.defineProperty(exports, "orgpolicy_v2", { enumerable: true, get: function () { return v2_38.orgpolicy_v2; } });
var v1_152 = require("./apis/osconfig/v1");
Object.defineProperty(exports, "osconfig_v1", { enumerable: true, get: function () { return v1_152.osconfig_v1; } });
var v1alpha_18 = require("./apis/osconfig/v1alpha");
Object.defineProperty(exports, "osconfig_v1alpha", { enumerable: true, get: function () { return v1alpha_18.osconfig_v1alpha; } });
var v1beta_20 = require("./apis/osconfig/v1beta");
Object.defineProperty(exports, "osconfig_v1beta", { enumerable: true, get: function () { return v1beta_20.osconfig_v1beta; } });
var v1_153 = require("./apis/oslogin/v1");
Object.defineProperty(exports, "oslogin_v1", { enumerable: true, get: function () { return v1_153.oslogin_v1; } });
var v1alpha_19 = require("./apis/oslogin/v1alpha");
Object.defineProperty(exports, "oslogin_v1alpha", { enumerable: true, get: function () { return v1alpha_19.oslogin_v1alpha; } });
var v1beta_21 = require("./apis/oslogin/v1beta");
Object.defineProperty(exports, "oslogin_v1beta", { enumerable: true, get: function () { return v1beta_21.oslogin_v1beta; } });
var v5_1 = require("./apis/pagespeedonline/v5");
Object.defineProperty(exports, "pagespeedonline_v5", { enumerable: true, get: function () { return v5_1.pagespeedonline_v5; } });
var v1_154 = require("./apis/paymentsresellersubscription/v1");
Object.defineProperty(exports, "paymentsresellersubscription_v1", { enumerable: true, get: function () { return v1_154.paymentsresellersubscription_v1; } });
var v1_155 = require("./apis/people/v1");
Object.defineProperty(exports, "people_v1", { enumerable: true, get: function () { return v1_155.people_v1; } });
var v1_156 = require("./apis/places/v1");
Object.defineProperty(exports, "places_v1", { enumerable: true, get: function () { return v1_156.places_v1; } });
var v3_13 = require("./apis/playablelocations/v3");
Object.defineProperty(exports, "playablelocations_v3", { enumerable: true, get: function () { return v3_13.playablelocations_v3; } });
var v1_157 = require("./apis/playcustomapp/v1");
Object.defineProperty(exports, "playcustomapp_v1", { enumerable: true, get: function () { return v1_157.playcustomapp_v1; } });
var v1alpha1_12 = require("./apis/playdeveloperreporting/v1alpha1");
Object.defineProperty(exports, "playdeveloperreporting_v1alpha1", { enumerable: true, get: function () { return v1alpha1_12.playdeveloperreporting_v1alpha1; } });
var v1beta1_43 = require("./apis/playdeveloperreporting/v1beta1");
Object.defineProperty(exports, "playdeveloperreporting_v1beta1", { enumerable: true, get: function () { return v1beta1_43.playdeveloperreporting_v1beta1; } });
var v1alpha1_13 = require("./apis/playgrouping/v1alpha1");
Object.defineProperty(exports, "playgrouping_v1alpha1", { enumerable: true, get: function () { return v1alpha1_13.playgrouping_v1alpha1; } });
var v1_158 = require("./apis/playintegrity/v1");
Object.defineProperty(exports, "playintegrity_v1", { enumerable: true, get: function () { return v1_158.playintegrity_v1; } });
var v1_159 = require("./apis/plus/v1");
Object.defineProperty(exports, "plus_v1", { enumerable: true, get: function () { return v1_159.plus_v1; } });
var v1_160 = require("./apis/policyanalyzer/v1");
Object.defineProperty(exports, "policyanalyzer_v1", { enumerable: true, get: function () { return v1_160.policyanalyzer_v1; } });
var v1beta1_44 = require("./apis/policyanalyzer/v1beta1");
Object.defineProperty(exports, "policyanalyzer_v1beta1", { enumerable: true, get: function () { return v1beta1_44.policyanalyzer_v1beta1; } });
var v1_161 = require("./apis/policysimulator/v1");
Object.defineProperty(exports, "policysimulator_v1", { enumerable: true, get: function () { return v1_161.policysimulator_v1; } });
var v1alpha_20 = require("./apis/policysimulator/v1alpha");
Object.defineProperty(exports, "policysimulator_v1alpha", { enumerable: true, get: function () { return v1alpha_20.policysimulator_v1alpha; } });
var v1beta_22 = require("./apis/policysimulator/v1beta");
Object.defineProperty(exports, "policysimulator_v1beta", { enumerable: true, get: function () { return v1beta_22.policysimulator_v1beta; } });
var v1beta1_45 = require("./apis/policysimulator/v1beta1");
Object.defineProperty(exports, "policysimulator_v1beta1", { enumerable: true, get: function () { return v1beta1_45.policysimulator_v1beta1; } });
var v1_162 = require("./apis/policytroubleshooter/v1");
Object.defineProperty(exports, "policytroubleshooter_v1", { enumerable: true, get: function () { return v1_162.policytroubleshooter_v1; } });
var v1beta_23 = require("./apis/policytroubleshooter/v1beta");
Object.defineProperty(exports, "policytroubleshooter_v1beta", { enumerable: true, get: function () { return v1beta_23.policytroubleshooter_v1beta; } });
var v1_163 = require("./apis/pollen/v1");
Object.defineProperty(exports, "pollen_v1", { enumerable: true, get: function () { return v1_163.pollen_v1; } });
var v1_164 = require("./apis/poly/v1");
Object.defineProperty(exports, "poly_v1", { enumerable: true, get: function () { return v1_164.poly_v1; } });
var v1_165 = require("./apis/privateca/v1");
Object.defineProperty(exports, "privateca_v1", { enumerable: true, get: function () { return v1_165.privateca_v1; } });
var v1beta1_46 = require("./apis/privateca/v1beta1");
Object.defineProperty(exports, "privateca_v1beta1", { enumerable: true, get: function () { return v1beta1_46.privateca_v1beta1; } });
var v1alpha1_14 = require("./apis/prod_tt_sasportal/v1alpha1");
Object.defineProperty(exports, "prod_tt_sasportal_v1alpha1", { enumerable: true, get: function () { return v1alpha1_14.prod_tt_sasportal_v1alpha1; } });
var v1_166 = require("./apis/publicca/v1");
Object.defineProperty(exports, "publicca_v1", { enumerable: true, get: function () { return v1_166.publicca_v1; } });
var v1alpha1_15 = require("./apis/publicca/v1alpha1");
Object.defineProperty(exports, "publicca_v1alpha1", { enumerable: true, get: function () { return v1alpha1_15.publicca_v1alpha1; } });
var v1beta1_47 = require("./apis/publicca/v1beta1");
Object.defineProperty(exports, "publicca_v1beta1", { enumerable: true, get: function () { return v1beta1_47.publicca_v1beta1; } });
var v1_167 = require("./apis/pubsub/v1");
Object.defineProperty(exports, "pubsub_v1", { enumerable: true, get: function () { return v1_167.pubsub_v1; } });
var v1beta1a_1 = require("./apis/pubsub/v1beta1a");
Object.defineProperty(exports, "pubsub_v1beta1a", { enumerable: true, get: function () { return v1beta1a_1.pubsub_v1beta1a; } });
var v1beta2_11 = require("./apis/pubsub/v1beta2");
Object.defineProperty(exports, "pubsub_v1beta2", { enumerable: true, get: function () { return v1beta2_11.pubsub_v1beta2; } });
var v1_168 = require("./apis/pubsublite/v1");
Object.defineProperty(exports, "pubsublite_v1", { enumerable: true, get: function () { return v1_168.pubsublite_v1; } });
var v1_169 = require("./apis/rapidmigrationassessment/v1");
Object.defineProperty(exports, "rapidmigrationassessment_v1", { enumerable: true, get: function () { return v1_169.rapidmigrationassessment_v1; } });
var v1_170 = require("./apis/readerrevenuesubscriptionlinking/v1");
Object.defineProperty(exports, "readerrevenuesubscriptionlinking_v1", { enumerable: true, get: function () { return v1_170.readerrevenuesubscriptionlinking_v1; } });
var v1_171 = require("./apis/realtimebidding/v1");
Object.defineProperty(exports, "realtimebidding_v1", { enumerable: true, get: function () { return v1_171.realtimebidding_v1; } });
var v1alpha_21 = require("./apis/realtimebidding/v1alpha");
Object.defineProperty(exports, "realtimebidding_v1alpha", { enumerable: true, get: function () { return v1alpha_21.realtimebidding_v1alpha; } });
var v1_172 = require("./apis/recaptchaenterprise/v1");
Object.defineProperty(exports, "recaptchaenterprise_v1", { enumerable: true, get: function () { return v1_172.recaptchaenterprise_v1; } });
var v1beta1_48 = require("./apis/recommendationengine/v1beta1");
Object.defineProperty(exports, "recommendationengine_v1beta1", { enumerable: true, get: function () { return v1beta1_48.recommendationengine_v1beta1; } });
var v1_173 = require("./apis/recommender/v1");
Object.defineProperty(exports, "recommender_v1", { enumerable: true, get: function () { return v1_173.recommender_v1; } });
var v1beta1_49 = require("./apis/recommender/v1beta1");
Object.defineProperty(exports, "recommender_v1beta1", { enumerable: true, get: function () { return v1beta1_49.recommender_v1beta1; } });
var v1_174 = require("./apis/redis/v1");
Object.defineProperty(exports, "redis_v1", { enumerable: true, get: function () { return v1_174.redis_v1; } });
var v1beta1_50 = require("./apis/redis/v1beta1");
Object.defineProperty(exports, "redis_v1beta1", { enumerable: true, get: function () { return v1beta1_50.redis_v1beta1; } });
var v1_175 = require("./apis/remotebuildexecution/v1");
Object.defineProperty(exports, "remotebuildexecution_v1", { enumerable: true, get: function () { return v1_175.remotebuildexecution_v1; } });
var v1alpha_22 = require("./apis/remotebuildexecution/v1alpha");
Object.defineProperty(exports, "remotebuildexecution_v1alpha", { enumerable: true, get: function () { return v1alpha_22.remotebuildexecution_v1alpha; } });
var v2_39 = require("./apis/remotebuildexecution/v2");
Object.defineProperty(exports, "remotebuildexecution_v2", { enumerable: true, get: function () { return v2_39.remotebuildexecution_v2; } });
var v1_176 = require("./apis/reseller/v1");
Object.defineProperty(exports, "reseller_v1", { enumerable: true, get: function () { return v1_176.reseller_v1; } });
var v1_177 = require("./apis/resourcesettings/v1");
Object.defineProperty(exports, "resourcesettings_v1", { enumerable: true, get: function () { return v1_177.resourcesettings_v1; } });
var v2_40 = require("./apis/retail/v2");
Object.defineProperty(exports, "retail_v2", { enumerable: true, get: function () { return v2_40.retail_v2; } });
var v2alpha_3 = require("./apis/retail/v2alpha");
Object.defineProperty(exports, "retail_v2alpha", { enumerable: true, get: function () { return v2alpha_3.retail_v2alpha; } });
var v2beta_9 = require("./apis/retail/v2beta");
Object.defineProperty(exports, "retail_v2beta", { enumerable: true, get: function () { return v2beta_9.retail_v2beta; } });
var v1_178 = require("./apis/run/v1");
Object.defineProperty(exports, "run_v1", { enumerable: true, get: function () { return v1_178.run_v1; } });
var v1alpha1_16 = require("./apis/run/v1alpha1");
Object.defineProperty(exports, "run_v1alpha1", { enumerable: true, get: function () { return v1alpha1_16.run_v1alpha1; } });
var v1beta1_51 = require("./apis/run/v1beta1");
Object.defineProperty(exports, "run_v1beta1", { enumerable: true, get: function () { return v1beta1_51.run_v1beta1; } });
var v2_41 = require("./apis/run/v2");
Object.defineProperty(exports, "run_v2", { enumerable: true, get: function () { return v2_41.run_v2; } });
var v1_179 = require("./apis/runtimeconfig/v1");
Object.defineProperty(exports, "runtimeconfig_v1", { enumerable: true, get: function () { return v1_179.runtimeconfig_v1; } });
var v1beta1_52 = require("./apis/runtimeconfig/v1beta1");
Object.defineProperty(exports, "runtimeconfig_v1beta1", { enumerable: true, get: function () { return v1beta1_52.runtimeconfig_v1beta1; } });
var v4_5 = require("./apis/safebrowsing/v4");
Object.defineProperty(exports, "safebrowsing_v4", { enumerable: true, get: function () { return v4_5.safebrowsing_v4; } });
var v5_2 = require("./apis/safebrowsing/v5");
Object.defineProperty(exports, "safebrowsing_v5", { enumerable: true, get: function () { return v5_2.safebrowsing_v5; } });
var v1alpha1_17 = require("./apis/sasportal/v1alpha1");
Object.defineProperty(exports, "sasportal_v1alpha1", { enumerable: true, get: function () { return v1alpha1_17.sasportal_v1alpha1; } });
var v1_180 = require("./apis/script/v1");
Object.defineProperty(exports, "script_v1", { enumerable: true, get: function () { return v1_180.script_v1; } });
var v0_1 = require("./apis/searchads360/v0");
Object.defineProperty(exports, "searchads360_v0", { enumerable: true, get: function () { return v0_1.searchads360_v0; } });
var v1_181 = require("./apis/searchconsole/v1");
Object.defineProperty(exports, "searchconsole_v1", { enumerable: true, get: function () { return v1_181.searchconsole_v1; } });
var v1_182 = require("./apis/secretmanager/v1");
Object.defineProperty(exports, "secretmanager_v1", { enumerable: true, get: function () { return v1_182.secretmanager_v1; } });
var v1beta1_53 = require("./apis/secretmanager/v1beta1");
Object.defineProperty(exports, "secretmanager_v1beta1", { enumerable: true, get: function () { return v1beta1_53.secretmanager_v1beta1; } });
var v1beta2_12 = require("./apis/secretmanager/v1beta2");
Object.defineProperty(exports, "secretmanager_v1beta2", { enumerable: true, get: function () { return v1beta2_12.secretmanager_v1beta2; } });
var v1_183 = require("./apis/securitycenter/v1");
Object.defineProperty(exports, "securitycenter_v1", { enumerable: true, get: function () { return v1_183.securitycenter_v1; } });
var v1beta1_54 = require("./apis/securitycenter/v1beta1");
Object.defineProperty(exports, "securitycenter_v1beta1", { enumerable: true, get: function () { return v1beta1_54.securitycenter_v1beta1; } });
var v1beta2_13 = require("./apis/securitycenter/v1beta2");
Object.defineProperty(exports, "securitycenter_v1beta2", { enumerable: true, get: function () { return v1beta2_13.securitycenter_v1beta2; } });
var v1p1alpha1_1 = require("./apis/securitycenter/v1p1alpha1");
Object.defineProperty(exports, "securitycenter_v1p1alpha1", { enumerable: true, get: function () { return v1p1alpha1_1.securitycenter_v1p1alpha1; } });
var v1p1beta1_2 = require("./apis/securitycenter/v1p1beta1");
Object.defineProperty(exports, "securitycenter_v1p1beta1", { enumerable: true, get: function () { return v1p1beta1_2.securitycenter_v1p1beta1; } });
var v1_184 = require("./apis/serviceconsumermanagement/v1");
Object.defineProperty(exports, "serviceconsumermanagement_v1", { enumerable: true, get: function () { return v1_184.serviceconsumermanagement_v1; } });
var v1beta1_55 = require("./apis/serviceconsumermanagement/v1beta1");
Object.defineProperty(exports, "serviceconsumermanagement_v1beta1", { enumerable: true, get: function () { return v1beta1_55.serviceconsumermanagement_v1beta1; } });
var v1_185 = require("./apis/servicecontrol/v1");
Object.defineProperty(exports, "servicecontrol_v1", { enumerable: true, get: function () { return v1_185.servicecontrol_v1; } });
var v2_42 = require("./apis/servicecontrol/v2");
Object.defineProperty(exports, "servicecontrol_v2", { enumerable: true, get: function () { return v2_42.servicecontrol_v2; } });
var v1_186 = require("./apis/servicedirectory/v1");
Object.defineProperty(exports, "servicedirectory_v1", { enumerable: true, get: function () { return v1_186.servicedirectory_v1; } });
var v1beta1_56 = require("./apis/servicedirectory/v1beta1");
Object.defineProperty(exports, "servicedirectory_v1beta1", { enumerable: true, get: function () { return v1beta1_56.servicedirectory_v1beta1; } });
var v1_187 = require("./apis/servicemanagement/v1");
Object.defineProperty(exports, "servicemanagement_v1", { enumerable: true, get: function () { return v1_187.servicemanagement_v1; } });
var v1_188 = require("./apis/servicenetworking/v1");
Object.defineProperty(exports, "servicenetworking_v1", { enumerable: true, get: function () { return v1_188.servicenetworking_v1; } });
var v1beta_24 = require("./apis/servicenetworking/v1beta");
Object.defineProperty(exports, "servicenetworking_v1beta", { enumerable: true, get: function () { return v1beta_24.servicenetworking_v1beta; } });
var v1_189 = require("./apis/serviceusage/v1");
Object.defineProperty(exports, "serviceusage_v1", { enumerable: true, get: function () { return v1_189.serviceusage_v1; } });
var v1beta1_57 = require("./apis/serviceusage/v1beta1");
Object.defineProperty(exports, "serviceusage_v1beta1", { enumerable: true, get: function () { return v1beta1_57.serviceusage_v1beta1; } });
var v4_6 = require("./apis/sheets/v4");
Object.defineProperty(exports, "sheets_v4", { enumerable: true, get: function () { return v4_6.sheets_v4; } });
var v1_190 = require("./apis/siteVerification/v1");
Object.defineProperty(exports, "siteVerification_v1", { enumerable: true, get: function () { return v1_190.siteVerification_v1; } });
var v1_191 = require("./apis/slides/v1");
Object.defineProperty(exports, "slides_v1", { enumerable: true, get: function () { return v1_191.slides_v1; } });
var v1_192 = require("./apis/smartdevicemanagement/v1");
Object.defineProperty(exports, "smartdevicemanagement_v1", { enumerable: true, get: function () { return v1_192.smartdevicemanagement_v1; } });
var v1_193 = require("./apis/solar/v1");
Object.defineProperty(exports, "solar_v1", { enumerable: true, get: function () { return v1_193.solar_v1; } });
var v1_194 = require("./apis/sourcerepo/v1");
Object.defineProperty(exports, "sourcerepo_v1", { enumerable: true, get: function () { return v1_194.sourcerepo_v1; } });
var v1_195 = require("./apis/spanner/v1");
Object.defineProperty(exports, "spanner_v1", { enumerable: true, get: function () { return v1_195.spanner_v1; } });
var v1_196 = require("./apis/speech/v1");
Object.defineProperty(exports, "speech_v1", { enumerable: true, get: function () { return v1_196.speech_v1; } });
var v1p1beta1_3 = require("./apis/speech/v1p1beta1");
Object.defineProperty(exports, "speech_v1p1beta1", { enumerable: true, get: function () { return v1p1beta1_3.speech_v1p1beta1; } });
var v2beta1_6 = require("./apis/speech/v2beta1");
Object.defineProperty(exports, "speech_v2beta1", { enumerable: true, get: function () { return v2beta1_6.speech_v2beta1; } });
var v1beta4_1 = require("./apis/sql/v1beta4");
Object.defineProperty(exports, "sql_v1beta4", { enumerable: true, get: function () { return v1beta4_1.sql_v1beta4; } });
var v1_197 = require("./apis/sqladmin/v1");
Object.defineProperty(exports, "sqladmin_v1", { enumerable: true, get: function () { return v1_197.sqladmin_v1; } });
var v1beta4_2 = require("./apis/sqladmin/v1beta4");
Object.defineProperty(exports, "sqladmin_v1beta4", { enumerable: true, get: function () { return v1beta4_2.sqladmin_v1beta4; } });
var v1_198 = require("./apis/storage/v1");
Object.defineProperty(exports, "storage_v1", { enumerable: true, get: function () { return v1_198.storage_v1; } });
var v1beta2_14 = require("./apis/storage/v1beta2");
Object.defineProperty(exports, "storage_v1beta2", { enumerable: true, get: function () { return v1beta2_14.storage_v1beta2; } });
var v1_199 = require("./apis/storagetransfer/v1");
Object.defineProperty(exports, "storagetransfer_v1", { enumerable: true, get: function () { return v1_199.storagetransfer_v1; } });
var v1_200 = require("./apis/streetviewpublish/v1");
Object.defineProperty(exports, "streetviewpublish_v1", { enumerable: true, get: function () { return v1_200.streetviewpublish_v1; } });
var v1_201 = require("./apis/sts/v1");
Object.defineProperty(exports, "sts_v1", { enumerable: true, get: function () { return v1_201.sts_v1; } });
var v1beta_25 = require("./apis/sts/v1beta");
Object.defineProperty(exports, "sts_v1beta", { enumerable: true, get: function () { return v1beta_25.sts_v1beta; } });
var v1_202 = require("./apis/tagmanager/v1");
Object.defineProperty(exports, "tagmanager_v1", { enumerable: true, get: function () { return v1_202.tagmanager_v1; } });
var v2_43 = require("./apis/tagmanager/v2");
Object.defineProperty(exports, "tagmanager_v2", { enumerable: true, get: function () { return v2_43.tagmanager_v2; } });
var v1_203 = require("./apis/tasks/v1");
Object.defineProperty(exports, "tasks_v1", { enumerable: true, get: function () { return v1_203.tasks_v1; } });
var v1_204 = require("./apis/testing/v1");
Object.defineProperty(exports, "testing_v1", { enumerable: true, get: function () { return v1_204.testing_v1; } });
var v1_205 = require("./apis/texttospeech/v1");
Object.defineProperty(exports, "texttospeech_v1", { enumerable: true, get: function () { return v1_205.texttospeech_v1; } });
var v1beta1_58 = require("./apis/texttospeech/v1beta1");
Object.defineProperty(exports, "texttospeech_v1beta1", { enumerable: true, get: function () { return v1beta1_58.texttospeech_v1beta1; } });
var v1beta3_3 = require("./apis/toolresults/v1beta3");
Object.defineProperty(exports, "toolresults_v1beta3", { enumerable: true, get: function () { return v1beta3_3.toolresults_v1beta3; } });
var v1_206 = require("./apis/tpu/v1");
Object.defineProperty(exports, "tpu_v1", { enumerable: true, get: function () { return v1_206.tpu_v1; } });
var v1alpha1_18 = require("./apis/tpu/v1alpha1");
Object.defineProperty(exports, "tpu_v1alpha1", { enumerable: true, get: function () { return v1alpha1_18.tpu_v1alpha1; } });
var v2_44 = require("./apis/tpu/v2");
Object.defineProperty(exports, "tpu_v2", { enumerable: true, get: function () { return v2_44.tpu_v2; } });
var v2alpha1_2 = require("./apis/tpu/v2alpha1");
Object.defineProperty(exports, "tpu_v2alpha1", { enumerable: true, get: function () { return v2alpha1_2.tpu_v2alpha1; } });
var v2_45 = require("./apis/trafficdirector/v2");
Object.defineProperty(exports, "trafficdirector_v2", { enumerable: true, get: function () { return v2_45.trafficdirector_v2; } });
var v3_14 = require("./apis/trafficdirector/v3");
Object.defineProperty(exports, "trafficdirector_v3", { enumerable: true, get: function () { return v3_14.trafficdirector_v3; } });
var v1_207 = require("./apis/transcoder/v1");
Object.defineProperty(exports, "transcoder_v1", { enumerable: true, get: function () { return v1_207.transcoder_v1; } });
var v1beta1_59 = require("./apis/transcoder/v1beta1");
Object.defineProperty(exports, "transcoder_v1beta1", { enumerable: true, get: function () { return v1beta1_59.transcoder_v1beta1; } });
var v2_46 = require("./apis/translate/v2");
Object.defineProperty(exports, "translate_v2", { enumerable: true, get: function () { return v2_46.translate_v2; } });
var v3_15 = require("./apis/translate/v3");
Object.defineProperty(exports, "translate_v3", { enumerable: true, get: function () { return v3_15.translate_v3; } });
var v3beta1_2 = require("./apis/translate/v3beta1");
Object.defineProperty(exports, "translate_v3beta1", { enumerable: true, get: function () { return v3beta1_2.translate_v3beta1; } });
var v1_208 = require("./apis/travelimpactmodel/v1");
Object.defineProperty(exports, "travelimpactmodel_v1", { enumerable: true, get: function () { return v1_208.travelimpactmodel_v1; } });
var v1_209 = require("./apis/vault/v1");
Object.defineProperty(exports, "vault_v1", { enumerable: true, get: function () { return v1_209.vault_v1; } });
var v1_210 = require("./apis/vectortile/v1");
Object.defineProperty(exports, "vectortile_v1", { enumerable: true, get: function () { return v1_210.vectortile_v1; } });
var v1_211 = require("./apis/verifiedaccess/v1");
Object.defineProperty(exports, "verifiedaccess_v1", { enumerable: true, get: function () { return v1_211.verifiedaccess_v1; } });
var v2_47 = require("./apis/verifiedaccess/v2");
Object.defineProperty(exports, "verifiedaccess_v2", { enumerable: true, get: function () { return v2_47.verifiedaccess_v2; } });
var v1_212 = require("./apis/versionhistory/v1");
Object.defineProperty(exports, "versionhistory_v1", { enumerable: true, get: function () { return v1_212.versionhistory_v1; } });
var v1_213 = require("./apis/videointelligence/v1");
Object.defineProperty(exports, "videointelligence_v1", { enumerable: true, get: function () { return v1_213.videointelligence_v1; } });
var v1beta2_15 = require("./apis/videointelligence/v1beta2");
Object.defineProperty(exports, "videointelligence_v1beta2", { enumerable: true, get: function () { return v1beta2_15.videointelligence_v1beta2; } });
var v1p1beta1_4 = require("./apis/videointelligence/v1p1beta1");
Object.defineProperty(exports, "videointelligence_v1p1beta1", { enumerable: true, get: function () { return v1p1beta1_4.videointelligence_v1p1beta1; } });
var v1p2beta1_1 = require("./apis/videointelligence/v1p2beta1");
Object.defineProperty(exports, "videointelligence_v1p2beta1", { enumerable: true, get: function () { return v1p2beta1_1.videointelligence_v1p2beta1; } });
var v1p3beta1_1 = require("./apis/videointelligence/v1p3beta1");
Object.defineProperty(exports, "videointelligence_v1p3beta1", { enumerable: true, get: function () { return v1p3beta1_1.videointelligence_v1p3beta1; } });
var v1_214 = require("./apis/vision/v1");
Object.defineProperty(exports, "vision_v1", { enumerable: true, get: function () { return v1_214.vision_v1; } });
var v1p1beta1_5 = require("./apis/vision/v1p1beta1");
Object.defineProperty(exports, "vision_v1p1beta1", { enumerable: true, get: function () { return v1p1beta1_5.vision_v1p1beta1; } });
var v1p2beta1_2 = require("./apis/vision/v1p2beta1");
Object.defineProperty(exports, "vision_v1p2beta1", { enumerable: true, get: function () { return v1p2beta1_2.vision_v1p2beta1; } });
var v1_215 = require("./apis/vmmigration/v1");
Object.defineProperty(exports, "vmmigration_v1", { enumerable: true, get: function () { return v1_215.vmmigration_v1; } });
var v1alpha1_19 = require("./apis/vmmigration/v1alpha1");
Object.defineProperty(exports, "vmmigration_v1alpha1", { enumerable: true, get: function () { return v1alpha1_19.vmmigration_v1alpha1; } });
var v1_216 = require("./apis/vmwareengine/v1");
Object.defineProperty(exports, "vmwareengine_v1", { enumerable: true, get: function () { return v1_216.vmwareengine_v1; } });
var v1_217 = require("./apis/vpcaccess/v1");
Object.defineProperty(exports, "vpcaccess_v1", { enumerable: true, get: function () { return v1_217.vpcaccess_v1; } });
var v1beta1_60 = require("./apis/vpcaccess/v1beta1");
Object.defineProperty(exports, "vpcaccess_v1beta1", { enumerable: true, get: function () { return v1beta1_60.vpcaccess_v1beta1; } });
var v1_218 = require("./apis/walletobjects/v1");
Object.defineProperty(exports, "walletobjects_v1", { enumerable: true, get: function () { return v1_218.walletobjects_v1; } });
var v1_219 = require("./apis/webfonts/v1");
Object.defineProperty(exports, "webfonts_v1", { enumerable: true, get: function () { return v1_219.webfonts_v1; } });
var v3_16 = require("./apis/webmasters/v3");
Object.defineProperty(exports, "webmasters_v3", { enumerable: true, get: function () { return v3_16.webmasters_v3; } });
var v1_220 = require("./apis/webrisk/v1");
Object.defineProperty(exports, "webrisk_v1", { enumerable: true, get: function () { return v1_220.webrisk_v1; } });
var v1_221 = require("./apis/websecurityscanner/v1");
Object.defineProperty(exports, "websecurityscanner_v1", { enumerable: true, get: function () { return v1_221.websecurityscanner_v1; } });
var v1alpha_23 = require("./apis/websecurityscanner/v1alpha");
Object.defineProperty(exports, "websecurityscanner_v1alpha", { enumerable: true, get: function () { return v1alpha_23.websecurityscanner_v1alpha; } });
var v1beta_26 = require("./apis/websecurityscanner/v1beta");
Object.defineProperty(exports, "websecurityscanner_v1beta", { enumerable: true, get: function () { return v1beta_26.websecurityscanner_v1beta; } });
var v1_222 = require("./apis/workflowexecutions/v1");
Object.defineProperty(exports, "workflowexecutions_v1", { enumerable: true, get: function () { return v1_222.workflowexecutions_v1; } });
var v1beta_27 = require("./apis/workflowexecutions/v1beta");
Object.defineProperty(exports, "workflowexecutions_v1beta", { enumerable: true, get: function () { return v1beta_27.workflowexecutions_v1beta; } });
var v1_223 = require("./apis/workflows/v1");
Object.defineProperty(exports, "workflows_v1", { enumerable: true, get: function () { return v1_223.workflows_v1; } });
var v1beta_28 = require("./apis/workflows/v1beta");
Object.defineProperty(exports, "workflows_v1beta", { enumerable: true, get: function () { return v1beta_28.workflows_v1beta; } });
var v1_224 = require("./apis/workloadmanager/v1");
Object.defineProperty(exports, "workloadmanager_v1", { enumerable: true, get: function () { return v1_224.workloadmanager_v1; } });
var v1_225 = require("./apis/workspaceevents/v1");
Object.defineProperty(exports, "workspaceevents_v1", { enumerable: true, get: function () { return v1_225.workspaceevents_v1; } });
var v1_226 = require("./apis/workstations/v1");
Object.defineProperty(exports, "workstations_v1", { enumerable: true, get: function () { return v1_226.workstations_v1; } });
var v1beta_29 = require("./apis/workstations/v1beta");
Object.defineProperty(exports, "workstations_v1beta", { enumerable: true, get: function () { return v1beta_29.workstations_v1beta; } });
var v3_17 = require("./apis/youtube/v3");
Object.defineProperty(exports, "youtube_v3", { enumerable: true, get: function () { return v3_17.youtube_v3; } });
var v1_227 = require("./apis/youtubeAnalytics/v1");
Object.defineProperty(exports, "youtubeAnalytics_v1", { enumerable: true, get: function () { return v1_227.youtubeAnalytics_v1; } });
var v2_48 = require("./apis/youtubeAnalytics/v2");
Object.defineProperty(exports, "youtubeAnalytics_v2", { enumerable: true, get: function () { return v2_48.youtubeAnalytics_v2; } });
var v1_228 = require("./apis/youtubereporting/v1");
Object.defineProperty(exports, "youtubereporting_v1", { enumerable: true, get: function () { return v1_228.youtubereporting_v1; } });
